const messages = {
	ru: {
		translations: {
			all_departments: 'Все отделы',
			about: 'О нас',
			above_the_same: 'Сверху точно так же',
			action: 'Действие',
			ad: 'Объявление',
			add: 'Добавлять',
			add_option: 'добавить опцию',
			add_payment: 'Добавить платеж',
			add_question: 'Добавить вопрос',
			add_receipt: 'Добавить квитанцию',
			address: 'Адрес',
			admin: 'Администрация',
			advance: 'Продвигать',
			affected: 'достиг',
			air_transport: 'Воздушный транспорт',
			all: 'Все',
			allies_of: 'союзники',
			almost_there: 'Почти готово',
			already_offset: 'уже компенсировано',
			already_registered: 'Уже зарегистрирован? Нажмите здесь и введите',
			and: 'и',
			and_more: 'и больше',
			approve: 'Согласовать',
			asc: 'Асцендент',
			awaiting_email_confirmation: 'Ожидание подтверждения по электронной почте!',
			back: 'Вернись',
			balance: 'Баланс',
			balance_avaliable: 'Доступный баланс',
			banned: 'Запрещено',
			before_contact: 'Прежде чем связаться с нами',
			binary: 'Бинарный',
			birth: 'Рождение',
			blind_man: 'перевернутый',
			but_possible_register:
				'Тем не менее, зарегистрироваться можно, но это не будет связано ни с одним из пригласивших друзей.',
			buyer: 'Покупатель',
			by: 'за',
			bye: 'До скорого',
			call_to: 'Позвонить',
			canceled: 'Отменено',
			calls: 'называется',
			cancel: 'Отмена',
			cash_payment: 'Оплата наличными',
			cash_payment_return: 'Возврат денежных средств',
			cash_receipt: 'наличные поступления',
			check_payment: 'Оплата чеком',
			check_payment_return: 'Возврат платежа чеком',
			check_receipt: 'получение по чеку',
			choose: 'Выбирать',
			city: 'Город',
			claims: 'Претензии',
			click_and_know: 'Нажмите и узнайте',
			click_to_copy_the_wallet: 'Нажмите, чтобы скопировать портфолио',
			client: 'Клиент',
			clients: 'Клиенты',
			cnh: 'Водительское удостоверение (водительское удостоверение)',
			cnpj: 'CNPJ',
			cnpj_invalid: 'Недействительный CNPJ',
			color: 'Цвет',
			commercial: 'Коммерческий',
			company: 'Название организации',
			complement: 'Дополнение',
			confirm_approve: 'подтвердить одобрение',
			confirm_delete: 'подтвердить удаление',
			confirm_new_password: 'Подтвердите новый пароль',
			confirm_password: 'Подтвердите пароль доступа',
			confirmed: 'Подтвержденный',
			confirmed_email: 'подтвержденный адрес электронной почты',
			contact: 'Контакт',
			contract: 'Договор',
			copy: 'Копировать',
			copy_success: 'Успешно скопировано!',
			correspondences: 'Переписка',
			country: 'Страна',
			cpf: 'КПФ',
			cpf_invalid: 'Неверный CPF',
			create_order_success: 'Заказ успешно создан!',
			cred: 'Кредит',
			cred_deb: 'Кредиты/дебеты',
			credit_card_payment: 'Оплата по кредитной карте',
			credit_card_payment_return: 'Возврат оплаты кредитной картой',
			credit_card_receipt: 'Квитанция кредитной карты',
			current: 'Текущий',
			current_password: 'Действующий пароль',
			dashboard: 'Панель управления',
			date: 'Дата',
			date_first_installment: 'Дата первого платежа',
			debit_card_payment: 'Оплата дебетовой картой',
			debit_card_payment_return: 'Возврат оплаты дебетовой картой',
			debit_card_receipt: 'Квитанция дебетовой карты',
			debt: 'Дебет',
			deleted_document: 'Документ был удален из вашего реестра',
			desc: 'Вниз',
			description: 'Описание',
			details: 'Подробности',
			developer: 'Разработчик',
			directly_indicated: 'прямо указано',
			directly_indicateds: 'Непосредственно назначенный',
			district: 'Район / Район',
			document: 'Документ',
			document_already_registered: 'Документ уже зарегистрирован',
			documents: 'Документы',
			donate: 'Пожертвовать',
			donor: 'Донор',
			dont_know_zip: 'Не знаете почтовый индекс? кликните сюда',
			downloads: 'Скачать файлы',
			drop_images: 'Нажмите или перетащите изображения сюда',
			due_date: 'Зрелость',
			edit: 'Редактировать',
			edit_option: 'вариант редактирования',
			edit_question: 'редактировать вопрос',
			email: 'Эл. адрес',
			email_already_registered: 'Электронная почта уже зарегистрирована',
			email_and_or_password_wrong: 'Неверный адрес электронной почты и/или пароль!',
			user_and_or_password_wrong: 'Неверный логин и/или пароль!',
			email_for_login: 'Этот адрес электронной почты будет вашим логином',
			email_or_password_wrong: 'Неправильный адрес электронной почты или пароль',
			user_or_password_wrong: 'неправильное имя пользователя или пароль',
			employee: 'Работник',
			employees: 'Сотрудники',
			error_confirm_password: 'Подтверждение и новый пароль должны совпадать',
			error_email: 'Неверный формат электронной почты',
			error_password: 'Неверный пароль',
			error_phone: 'Неправильный номер телефона',
			exchange: 'Обмен',
			expired: 'Истекший',
			extra: 'Дополнительный',
			extracts: 'экстракты',
			feedback: 'Возвращаться',
			final: 'Финал',
			financial: 'финансовый',
			financial_payment: 'финансовый платеж',
			financial_payment_return: 'Возврат финансового платежа',
			financial_receipt: 'финансовый чек',
			financing: 'Финансирование',
			financings: 'Финансирование',
			first_10_pages: 'первые 10 страниц',
			for_year: 'Ежегодно',
			forget_password: 'я забыл пароль',
			forgot_password: 'Забыли пароль',
			form: 'Форма',
			form_of_payment: 'Форма оплаты',
			found: 'Найденный',
			founds: 'Найденный',
			friend_indicated: 'Рекомендовано моими друзьями',
			friend_indicateds: 'Рекомендовано моими друзьями',
			fuel: 'Топливо',
			full_name: 'ФИО',
			grand_total: 'Общая сумма',
			home: 'Начинать',
			how_to_call: 'Как вы хотите, чтобы вас называли?',
			images: 'Картинки',
			in_order: 'Для того, чтобы',
			in_stock: 'В наличии',
			indicant: 'Индикатор',
			indicated_by: 'Вы номинированы',
			indicated_not_found: 'Указанный Индикатор не найден...',
			indicateds: 'Указанный',
			info: 'Информационные бюллетени',
			info_contact: 'Контактная информация',
			initial: 'Исходный',
			insert_new: 'Вставить новый',
			installments: 'рассрочка',
			invalid_images: 'недействительные изображения',
			invalid_token: 'Неверный токен',
			invite: 'Приглашать',
			invite_friends: 'Пригласить друзей',
			know_exact_result: 'Вы знаете точное значение?',
			learn_more: 'Узнать больше',
			link_email: 'Нажмите на ссылку, отправленную на зарегистрированный адрес электронной почты!',
			link_invite_copy: 'Ссылка на общий доступ успешно скопирована',
			liquid: 'Жидкость',
			loading: 'Загрузка',
			login: 'Входить',
			logout: 'Оставлять',
			manage_users: 'Управление пользователями',
			manager: 'Управляющий делами',
			manufacturer: 'Производитель',
			market: 'Рынок',
			message: 'Сообщение',
			mission: 'Миссия',
			mission_vision_values: 'Миссия, видение и ценности',
			model: 'Модель',
			movements: 'Движения',
			my_account: 'Мой счет',
			my_wallet: 'Мой бумажник',
			mystock: 'Мой запас',
			name: 'Имя',
			nature: 'Природа',
			new: 'Новый',
			new_financing: 'Новое финансирование',
			new_password: 'Новый пароль',
			new_seller: 'Новый продавец',
			news: 'Новости',
			next: 'Следующий',
			nick: 'Пользователь',
			no: 'Нет',
			no_approve: 'все еще не одобряю',
			no_delete: 'не удалять',
			no_results_found: 'результатов не найдено',
			no_services_or_products_added: 'Нет добавленных услуг или продуктов',
			none_friends_indicated: 'Я еще не пригласил друзей',
			not_authorized: 'Не авторизован',
			not_found: 'Не найден',
			not_login: 'Не удалось войти, проверьте данные и повторите попытку!',
			not_orders: 'Нет денежных переводов',
			not_registered: 'Еще не зарегистрированы? Нажмите здесь и зарегистрируйтесь',
			not_results: 'Записей не найдено',
			not_self_register: 'Не удалось зарегистрироваться',
			not_today_entry: 'У нас до сих пор нет билетов сегодня',
			notfound_ad: 'Объявлений не найдено',
			number: 'Число',
			of: 'в',
			at: 'в',
			off: 'Уничтожено',
			on_total: 'от общего',
			open: 'Открыть',
			opening: 'Открытие',
			operation: 'Спектакль',
			ordenation: 'рукоположение',
			order: 'порядок',
			orders: 'Запросы',
			other: 'Другой',
			other_services_or_products: 'Другие услуги или продукты',
			paid: 'Выплаченый',
			paid_date: 'день оплаты',
			partial: 'Частично оплачено',
			passport: 'Паспорт',
			password: 'Пароль',
			pay: 'Платить',
			pay_value_in: 'оплатить сумму в',
			payable: 'Платить',
			payer: 'Плательщик',
			payment: 'Оплата',
			pendent: 'В ожидании',
			personal_data: 'Персональные данные',
			prefecture_data: 'Данные мэрии',
			phone: 'Телефон',
			pix: 'ПИКС',
			pix_payment: 'Оплата в PIX',
			pix_payment_return: 'Возврат платежа в PIX',
			pix_receipt: 'Квитанция в формате PIX',
			plates: 'Тарелки',
			please_wait: 'Пожалуйста подождите',
			plus: 'Плюс',
			prev: 'Предыдущий',
			price: 'Цена',
			profile: 'Профиль',
			project: 'Проект',
			projects: 'Проекты',
			purchase: 'Покупка',
			quantity: 'Количество',
			quotation: 'Цена',
			receivable: 'Получать',
			recipient: 'получатель',
			recommendations: 'Рекомендации',
			redefine_password: 'Переопределить пароль',
			register: 'Записывать',
			registers: 'Рекорды',
			registers_users: 'Регистрация пользователя',
			reject: 'Отклонять',
			remaining: 'Оставшийся',
			required_data_not_sent: 'Необходимые данные не отправлены',
			resend_email: 'отправить электронное письмо повторно',
			residential: 'Жилой',
			result: 'Результат',
			results: 'Результаты',
			revoked: 'отозван',
			rg: 'Общая регистрация (идентификация)',
			roi: 'Ежедневный доход',
			sale: 'Распродажа',
			save: 'Сохранить',
			search: 'Поиск',
			secretary: 'Секретарь',
			see_advents: 'посмотреть объявления',
			see_details: 'смотрите подробности',
			see_details_wallet: 'Просмотр сведений о портфолио',
			see_financing: 'Посмотреть финансирование',
			see_order: 'Посмотреть заказ',
			see_user: 'Просмотр пользователей',
			select_vehicle: 'выберите автомобиль',
			self_register: 'регистр',
			seller: 'продавец',
			send_to: 'Отправить',
			settings: 'настройки',
			share_your_link: 'Поделитесь своей ссылкой',
			show: 'явиться к',
			show_from: 'показ от',
			sign_in_to_start_your_session: 'Войдите, чтобы начать сеанс',
			sign_up: 'регистр',
			sold: 'Продал',
			state: 'государство',
			status: 'Положение дел',
			street: 'Улица',
			subject: 'Тема',
			submit: 'Отправлять',
			submit_placeholder: 'Пожалуйста, заполните свой адрес электронной почты',
			subscribe: 'Подпишитесь, чтобы получать наши новости',
			subtitle: 'Подпись',
			success_update_user: 'Пользователь успешно обновлен!',
			suggestions: 'предложения',
			supervisor: 'Руководитель',
			support: 'Поддерживать',
			suspended: 'Приостановленный',
			this_value_in: 'Это значение в',
			title: 'Заголовок',
			to: 'До того как',
			to_pay: 'Удалить',
			to_receive: 'дебиторская задолженность',
			today_entry: 'Сегодняшняя запись',
			token_not_found_or_already_used: 'Токен не найден или уже используется',
			total: 'Общее',
			total_entry: 'Всего вход',
			transfer_payment: 'перевод платежа',
			transfer_payment_return: 'Возврат платежа за перевод',
			transfer_receipt: 'расписка в передаче',
			type: 'Тип',
			update: 'Обновить',
			used: 'Использовал',
			user: 'Пользователь',
			user_already_exists: 'Пользователь уже существует!',
			user_not_found: 'Пользователь не найден',
			user_not_logged: 'Пользователь не авторизован',
			users: 'Пользователи',
			users_list: 'Список пользователей',
			list: 'Список',
			valid_images: 'действительные изображения',
			value: 'Ценить',
			value_donate_on: 'Сумма пожертвования в',
			value_financed: 'финансируемая сумма',
			value_installment: 'стоимость взноса',
			values: 'Значения',
			vehicle: 'Транспортное средство',
			vehicles: 'Транспорт',
			view_order: 'Посмотреть заказ',
			vision: 'Зрение',
			visitor: 'Посетитель',
			voucher: 'Ваучер',
			wait_confirm: 'Ожидание подтверждения',
			waiting: 'Ожидающий',
			waiting_email: 'Регистрация прошла успешно, теперь осталось только подтвердить почту.',
			waiting_payment: 'Ожидание оплаты',
			wallet_generate: 'Создать портфолио',
			want_donate: 'я хочу пожертвовать',
			we_are: 'Находятся',
			welcome: 'Добро пожаловать',
			who_we_are: 'Кто мы есть',
			who_we_are_subtitle: 'Краткое описание о',
			will_expire_in: 'истекает через',
			with: 'с',
			year: 'Год',
			years: 'Годы',
			yes: 'Да',
			your_dashboard: 'Это ваша панель управления.',
			zipcode: 'Индекс',
			January: 'январь',
			February: 'февраль',
			March: 'Маршировать',
			April: 'апреля',
			May: 'Может',
			June: 'Июнь',
			July: 'Июль',
			August: 'Август',
			September: 'Сентябрь',
			October: 'Октябрь',
			November: 'ноябрь',
			December: 'Декабрь',
			location: 'Место нахождения',
			portfolio: 'портфолио',
			how_it_works: 'Как это работает',
			public_works: 'Общественные работы',
			private_works: 'Частные работы',
			a_crase: '',
			ao: 'к',
			lead: 'Вместе мы будем строить новое будущее.',
			system: 'Система',
			not_permission: 'У вас нет разрешения на доступ к этой странице.',
			in_construction: 'В разработке',
			reports: 'Отчеты',
			transparency: 'Прозрачность',
			casa_verde_amarela: 'желтый зеленый дом',
			people: 'Люди',
			person: 'Человек',
			register_people: 'Регистрация людей',
			fisica: 'Физическое лицо',
			juridica: 'Законопослушный гражданин',
			clean: 'Чистый',
			personal_infos: 'Информация',
			prefecture_infos: 'Информация',
			nationality: 'Национальность (Страна рождения)',
			naturalness: 'Место рождения (Город, где вы родились)',
			rgAgency: 'эмитент РГ',
			rgUf: 'Идентификационный статус',
			rgDate: 'Дата выдачи RG',
			cnhDate: 'Дата выдачи водительских прав',
			gender: 'Жанр',
			schooling: 'Образование',
			profession: 'Профессия',
			maritalStatus: 'Семейное положение',
			motherName: 'имя матери',
			fatherName: 'Имя Отца',
			dependents: 'иждивенцы',
			male: 'мужской род',
			feminine: 'Женский',
			single: 'Не женат не замужем',
			married: 'Женатый',
			widower: 'вдовец',
			divorced: 'В разводе',
			personal_addresses: 'Адреса',
			prefecture_addresses: 'Адреса',
			remove: 'Удалять',
			personal_contacts: 'Контакты',
			prefecture_contacts: 'Контакты',
			field_required: 'обязательное поле',
			data_saved: 'Сохраненные данные',
			data_added: 'Добавлены данные',
			resume_data: 'Сводка данных',
			no_literate: 'неграмотный',
			fundamental_incomplete: 'Элементарный - неполный',
			fundamental_complete: 'Элементарный - Полный',
			middle_incomplete: 'Средний — неполный',
			middle_complete: 'Средний - Полный',
			superior_incomplete: 'Неполное высшее',
			superior_complete: 'Окончил',
			pos_incomplete: 'Lato sensu - неполный',
			pos_complete: 'Лато сенсу - Полный',
			master_incomplete: 'Магистр - неполный',
			master_complete: 'Магистр - Полный',
			doctorate_incomplete: 'Докторантура - неполная',
			doctorate_complete: 'Докторантура - Полная',
			invalid_contact: 'Неверный формат контакта',
			obs: 'Комментарии',
			allRightsReserved: 'Все права защищены.',
			want_now_more_about: 'я хочу узнать больше о',
			no_repeat: 'Данные не могут быть повторены',
			personal_pcva: 'Программа «Зеленый и желтый дом»',
			compRenda: 'Тип подтверждения дохода',
			funcPublico: 'Вы государственный служащий?',
			decImpRenda: 'объявить подоходный налог',
			tempoCarteira: 'Как долго запись находится в портфолио',
			rendaIndividual: 'Индивидуальный валовой доход',
			comporRenda: 'Иди сочиняй доход с кем-нибудь',
			valorRenda2: 'Какова стоимость второго дохода',
			nascMaisVelhoRenda2: 'Какова дата рождения старшего?',
			fgts: 'Стоимость вашего ФГТС',
			entrada: 'Имеет ли он входную ценность? Если ДА, то какое значение',
			restricao: 'У вас есть ограничение на ваше имя? (СПЦ/Сераса)',
			interesse: 'Что представляет собой интересующий регион',
			estadoInteresse: 'государство',
			cidadeInteresse: 'Город',
			bairroInteresse: 'Район',
			program: 'Программа',
			programs: 'Програмное обеспечение',
			privacy_policies: 'Политика конфиденциальности',
			prefectures: 'мэрии',
			prefecture: 'Ратуша',
			fantasy: 'Фантастика',
			legalNature: 'Правовая природа',
			dev_econom: 'Экономическое развитие',
			nis: 'ПИС/НИШ',
			nis_already_registered: 'PIS/NIS уже зарегистрированы',
			group: 'Группа',
			created: 'Зарегистрировано в',
			person_found_in_other_prefecture: 'Лицо, уже зарегистрированное в другом муниципалитете',
			print: 'Распечатка',
			datas: 'Данные',
			qualquer: 'Любой',
			dou_fe: 'Я подтверждаю, что вышеуказанная информация верна.',

			change_indicant: 'Изменение',
			password_security: 'Пароль безопасности',
			confirm_password_security: 'Подтвердите пароль безопасности',
			plan: 'Плано',
			terms: 'Я принимаю условия использования',
			user_inactive: 'Неактивный пользователь',
		},
	},
};

export { messages };
