const messages = {
	fr: {
		translations: {
			all_departments: 'Tous les départements',
			about: 'À propos de nous',
			above_the_same: 'Ci-dessus exactement le même',
			action: 'Action',
			ad: 'Annonce',
			add: 'Ajouter',
			add_option: 'Ajouter une option',
			add_payment: 'Ajouter un paiement',
			add_question: 'Ajouter une question',
			add_receipt: 'Ajouter un reçu',
			address: 'Adresse',
			admin: 'Administration',
			advance: 'Avance',
			affected: 'Parvenu',
			air_transport: 'Transport aérien',
			all: 'Tous',
			allies_of: 'Alliés de',
			almost_there: 'Presque là',
			already_offset: "J'ai déjà compensé,",
			already_registered: 'Déjà inscrit ? Cliquez ici et entrez',
			and: 'et',
			and_more: 'et plus',
			approve: 'Approuver',
			asc: 'Ascendant',
			awaiting_email_confirmation: 'En attente de confirmation par e-mail !',
			back: 'Revenir',
			balance: 'Solde',
			balance_avaliable: 'Solde disponible',
			banned: 'Banni',
			before_contact: 'Avant de nous contacter',
			binary: 'Binaire',
			birth: 'Naissance',
			blind_man: 'Renversé',
			but_possible_register: "Même ainsi, il est possible de s'inscrire, mais cela ne sera lié à aucun ami parrain.,",
			buyer: 'Acheteur',
			by: 'par',
			bye: 'A plus tard',
			call_to: 'Appeler pour',
			canceled: 'Annulé',
			calls: 'Appelé',
			cancel: 'Annuler',
			cash_payment: 'Paiement en espèces',
			cash_payment_return: 'Retour de paiement en espèces',
			cash_receipt: 'ticket de caisse',
			check_payment: 'Paiement en chèque',
			check_payment_return: 'Retour de paiement par chèque',
			check_receipt: '« Encaissement par chèque »',
			choose: 'Choisir',
			city: 'Ville',
			claims: 'Réclamations',
			click_and_know: 'Cliquez et découvrez',
			click_to_copy_the_wallet: 'Cliquez pour copier le portefeuille',
			client: 'Client',
			clients: 'Clients',
			cnh: 'CNH (Habilitation)',
			cnpj: 'CNPJ',
			cnpj_invalid: 'CNPJ invalide',
			color: 'Couleur',
			commercial: 'Commercial',
			company: 'Nom de la compagnie',
			complement: 'Complément',
			confirm_approve: "Confirmer l'approbation,",
			confirm_delete: 'Confirmer la suppression',
			confirm_new_password: 'Confirmer le nouveau mot de passe',
			confirm_password: "Confirmer le mot de passe d'accès,",
			confirmed: 'Confirmé',
			confirmed_email: 'Courriel confirmé',
			contact: 'Contacter',
			contract: 'Contrat',
			copy: 'Copie',
			copy_success: 'Copié avec succès !',
			correspondences: 'Correspondance',
			country: 'Pays',
			cpf: 'PCF',
			cpf_invalid: 'CPF invalide',
			create_order_success: 'Commande créée avec succès !',
			cred: 'Crédit',
			cred_deb: 'Crédits/Débits',
			credit_card_payment: 'Paiement par carte de crédit',
			credit_card_payment_return: 'Retour paiement carte bancaire',
			credit_card_receipt: 'Reçu de carte de crédit',
			current: 'Courant',
			current_password: 'Mot de passe actuel',
			dashboard: 'Panneau de commande',
			date: 'Date',
			date_first_installment: 'Date du premier paiement',
			debit_card_payment: 'Paiement par carte de débit',
			debit_card_payment_return: 'Retour de paiement par carte de débit',
			debit_card_receipt: 'Reçu de carte de débit',
			debt: 'Dette',
			deleted_document: 'Le document a été supprimé de votre registre',
			desc: 'Vers le bas',
			description: 'La description',
			details: 'Des détails',
			developer: 'Développeur',
			directly_indicated: 'directement indiqué',
			directly_indicateds: 'Directement nommé',
			district: 'Quartier / quartier',
			document: 'Document',
			document_already_registered: 'Document déjà enregistré',
			documents: ' Documents ',
			donate: 'Donner',
			donor: 'Donneur',
			dont_know_zip: 'Vous ne connaissez pas le code postal ? Cliquez ici',
			downloads: 'Telecharger des fichiers',
			drop_images: 'Cliquez ou faites glisser les images ici',
			due_date: 'Maturité',
			edit: 'Éditer',
			edit_option: "Option d'édition,",
			edit_question: 'Modifier la question',
			email: 'E-mail',
			email_already_registered: 'Email déjà enregistré',
			email_and_or_password_wrong: 'Mauvais email et/ou mot de passe !',
			user_and_or_password_wrong: "Mauvais nom d'utilisateur et / ou mot de passe!,",
			email_for_login: 'Cet email sera votre login',
			email_or_password_wrong: 'Email ou mot de passe incorrect',
			user_or_password_wrong: 'Identifiant ou mot de passe incorrect',
			employee: 'Employé',
			employees: 'Employés',
			error_confirm_password: 'La confirmation et le nouveau mot de passe doivent être identiques',
			error_email: "Format d'email invalide,",
			error_password: 'Mot de passe incorrect',
			error_phone: 'Numéro de téléphone invalide',
			exchange: 'Échanger',
			expired: 'Expiré',
			extra: 'En plus',
			extracts: 'Extraits',
			feedback: 'Revenir',
			final: 'Final',
			financial: 'Financier',
			financial_payment: 'Paiement Financier',
			financial_payment_return: 'Relevé de paiement financier',
			financial_receipt: '« Reçu financier »',
			financing: 'Financement',
			financings: 'Financement',
			first_10_pages: '10 premières pages',
			for_year: 'Par an',
			forget_password: "J'ai oublié le mot de passe,",
			forgot_password: 'Mot de passe oublié',
			form: 'Former',
			form_of_payment: 'Forme de payement',
			found: 'Trouvé',
			founds: 'Trouvé',
			friend_indicated: 'Recommandé par mes amis',
			friend_indicateds: 'Recommandé par mes amis',
			fuel: 'Carburant',
			full_name: 'Nom et prénom',
			grand_total: 'Somme finale',
			home: 'Début',
			how_to_call: "Comment veux-tu qu'on t'appelle",
			images: 'Images',
			in_order: 'En ordre',
			in_stock: 'En stock',
			indicant: 'Indicateur',
			indicated_by: 'Vous êtes nommé par',
			indicated_not_found: "L'indicateur indiqué n'a pas été trouvé...,",
			indicateds: '« Nominés »',
			info: 'Informatif',
			info_contact: 'Informations de contact',
			initial: 'Initial',
			insert_new: 'Insérer nouveau',
			installments: 'Parcelles',
			invalid_images: 'Images invalides',
			invalid_token: 'Jeton invalide',
			invite: 'Inviter',
			invite_friends: 'Invite tes amis',
			know_exact_result: 'Connaissez-vous la valeur exacte ?',
			learn_more: 'Savoir plus',
			link_email: "Cliquez sur le lien envoyé à l'e-mail enregistré !,",
			link_invite_copy: 'Le lien de partage a été copié avec succès',
			liquid: 'Liquide',
			loading: 'Chargement',
			login: 'Entrer',
			logout: 'Partir',
			manage_users: 'Gérer les utilisateurs',
			manager: 'Gestionnaire',
			manufacturer: 'Fabricant',
			market: 'Marché',
			message: 'Message',
			mission: 'Mission',
			mission_vision_values: 'Mission, vision et valeurs',
			model: 'Modèle',
			movements: 'Mouvements',
			my_account: 'Mon compte',
			my_wallet: 'Mon portefeuille',
			mystock: 'Mon stock',
			name: 'Nom',
			nature: 'La nature',
			new: 'Nouveau',
			new_financing: 'Nouveaux financements',
			new_password: 'Nouveau mot de passe',
			new_seller: 'Nouveau vendeur',
			news: 'Nouvelles',
			next: 'Suivant',
			nick: 'Utilisateur',
			no: 'Pas',
			no_approve: 'Toujours pas approuvé',
			no_delete: 'Ne pas supprimer',
			no_results_found: 'Aucun résultat trouvé',
			no_services_or_products_added: 'Aucun service ou produit ajouté',
			none_friends_indicated: "Je n'ai pas encore parrainé d'amis,",
			not_authorized: 'Pas autorisé',
			not_found: 'Pas trouvé',
			not_login: 'Impossible de se connecter, vérifiez vos données et réessayez !',
			not_orders: 'Pas de mandats',
			not_registered: 'Pas encore inscrit? Cliquez ici et inscrivez-vous',
			not_results: 'Aucun enregistrement trouvé',
			not_self_register: "Impossible de s'inscrire,",
			not_today_entry: "Nous n'avons toujours pas eu de billets aujourd'hui,",
			notfound_ad: 'Aucune annonce trouvée',
			number: 'Nombre',
			of: 'dans',
			at: 'dans',
			off: 'Effacé',
			on_total: 'De Total',
			open: 'Ouvrir',
			opening: 'Ouverture',
			operation: 'Agissant',
			ordenation: 'Commander',
			order: 'Ordre',
			orders: 'Demandes',
			other: 'Autre',
			other_services_or_products: 'Autres services ou produits',
			paid: 'Payé',
			paid_date: 'Jour de paie',
			partial: '« Partiellement Payé »',
			passport: 'Passeport',
			password: 'Mot de passe',
			pay: 'Payer',
			pay_value_in: 'Payer le montant en',
			payable: 'Payer',
			payer: 'Payeur',
			payment: 'Paiement',
			pendent: 'En attente',
			personal_data: 'Données personnelles',
			prefecture_data: '« Données de la mairie »',
			phone: 'Téléphone fixe',
			pix: 'PIX',
			pix_payment: 'Paiement en PIX',
			pix_payment_return: 'Retour de paiement en PIX',
			pix_receipt: 'Réception en PIX',
			plates: 'assiettes',
			please_wait: "S'il vous plaît, attendez,",
			plus: 'Plus',
			prev: 'Précédent',
			price: 'Prix',
			profile: 'Profil',
			project: 'Projet',
			projects: 'Projets',
			purchase: 'Acheter',
			quantity: 'La quantité',
			quotation: 'Prix',
			receivable: 'Recevoir',
			recipient: 'Receveur',
			recommendations: 'Recommandations',
			redefine_password: 'Redéfinir le mot de passe',
			register: 'Enregistrer',
			registers: 'Enregistrements',
			registers_users: "Enregistrement de l'utilisateur,",
			reject: 'Rejeter',
			remaining: 'Restant',
			required_data_not_sent: 'Données requises non envoyées',
			resend_email: "Ré-envoyer l'email,",
			residential: 'Résidentiel',
			result: 'Résultat',
			results: 'Résultats',
			revoked: 'Révoqué',
			rg: 'Enregistrement général (Identité)',
			roi: 'Gains quotidiens',
			sale: 'Vente',
			save: 'Sauver',
			search: 'Rechercher',
			secretary: 'Secrétaire',
			see_advents: 'Voir les annonces',
			see_details: 'Voir les détails',
			see_details_wallet: 'Afficher les détails du portefeuille',
			see_financing: 'Voir le financement',
			see_order: "Voir l'ordre,",
			see_user: 'Afficher les utilisateurs',
			select_vehicle: 'Sélectionner un véhicule',
			self_register: "S'inscrire,",
			seller: 'Vendeur',
			send_to: 'Envoyer à',
			settings: 'Paramètres',
			share_your_link: 'Partagez votre lien',
			show: "Afficher jusqu'à,",
			show_from: 'Affichage depuis',
			sign_in_to_start_your_session: 'Veuillez vous connecter pour démarrer votre session',
			sign_up: "S'inscrire,",
			sold: 'Vendu',
			state: 'État',
			status: 'Statut',
			street: 'Rue',
			subject: 'Sujet',
			submit: 'Envoyer',
			submit_placeholder: 'Merci de renseigner votre email',
			subscribe: 'Inscrivez-vous pour recevoir nos actualités',
			subtitle: 'Légende',
			success_update_user: "L'utilisateur a été mis à jour avec succès !,",
			suggestions: 'Suggestions',
			supervisor: 'Superviseur',
			support: 'Soutien',
			suspended: 'Suspendu',
			this_value_in: 'Cette valeur en',
			title: 'Titre',
			to: "Jusqu'à ce que,",
			to_pay: 'Supprimer',
			to_receive: 'recevoir',
			today_entry: "L'entrée d'aujourd'hui,",
			token_not_found_or_already_used: 'Token introuvable ou déjà utilisé',
			total: 'Le total',
			total_entry: 'Entrée totale',
			transfer_payment: 'Paiement par virement',
			transfer_payment_return: 'Retour de paiement de transfert',
			transfer_receipt: 'Récépissé de transfert',
			type: 'Taper',
			update: 'Mettre à jour',
			used: 'Utilisé',
			user: 'Utilisateur',
			user_already_exists: "L'utilisateur existe déjà!,",
			user_not_found: 'Utilisateur non trouvé',
			user_not_logged: 'Utilisateur non connecté',
			users: 'Utilisateurs',
			users_list: "Liste d'utilisateur,",
			list: 'Liste',
			valid_images: 'Images valides',
			value: 'Évaluer',
			value_donate_on: 'Montant du don en',
			value_financed: 'montant financé',
			value_installment: 'Valeur du versement',
			values: 'Valeurs',
			vehicle: 'Véhicule',
			vehicles: 'Véhicules',
			view_order: "Voir l'ordre,",
			vision: 'Vue',
			visitor: 'Visiteur',
			voucher: 'Bon',
			wait_confirm: 'En attente de confirmation',
			waiting: 'En attendant',
			waiting_email: "L'inscription a été effectuée avec succès, il ne vous reste plus qu'à confirmer l'e-mail.,",
			waiting_payment: 'En attente de paiement',
			wallet_generate: 'Générer un portefeuille',
			want_donate: 'Je veux faire un don',
			we_are: 'Sont',
			welcome: 'Bienvenue',
			who_we_are: 'Qui nous sommes',
			who_we_are_subtitle: 'Une brève description de la',
			will_expire_in: 'expirera dans',
			with: 'avec',
			year: 'Année',
			years: 'Ans',
			yes: 'Oui',
			your_dashboard: 'Ceci est votre panneau de configuration.',
			zipcode: 'CODE POSTAL',
			January: 'Janvier',
			February: 'Février',
			March: 'Mars',
			April: 'Avril',
			May: 'Peut',
			June: 'Juin',
			July: 'Juillet',
			August: 'Août',
			September: 'Septembre',
			October: 'Octobre',
			November: 'Novembre',
			December: 'Décembre',
			location: 'Emplacement',
			portfolio: 'Portefeuille',
			how_it_works: 'Comment ça fonctionne',
			public_works: 'Travaux publics',
			private_works: 'Travaux privés',
			a_crase: 'Les',
			ao: 'pour',
			lead: 'Ensemble, nous construirons un nouvel avenir.',
			system: 'Système',
			not_permission: "Vous n'êtes pas autorisé à accéder à cette page,",
			in_construction: 'En construction',
			reports: 'Rapports',
			transparency: 'Transparence',
			casa_verde_amarela: 'Maison Verte Jaune',
			people: 'Personnes',
			person: 'La personne',
			register_people: 'Enregistrement des personnes',
			fisica: 'Personne physique',
			juridica: 'Personne morale',
			clean: 'Propre',
			personal_infos: 'Informations',
			prefecture_infos: 'Informations',
			nationality: 'Nationalité (Pays de naissance)',
			naturalness: 'Naturalité (Ville où tu es né)',
			rgAgency: 'Emetteur RG',
			rgUf: 'Etat des lieux du RG',
			rgDate: "Date d'émission du RG,",
			cnhDate: "Date d'émission CNH,",
			gender: 'Le genre',
			schooling: 'Éducation',
			profession: 'Métier',
			maritalStatus: 'État civil',
			motherName: 'Le nom de la mère',
			fatherName: 'Le nom du père',
			dependents: '« Personnes à charge »',
			male: 'Masculin',
			feminine: 'Femelle',
			single: 'Pas marié',
			married: 'Marié',
			widower: 'Veuf',
			divorced: 'Divorcé',
			personal_addresses: 'Adresses',
			prefecture_addresses: 'Adresses',
			remove: 'Retirer',
			personal_contacts: 'Contacts',
			prefecture_contacts: 'Contacts',
			field_required: 'Champ obligatoire',
			data_saved: 'Données enregistrées',
			data_added: 'Données ajoutées',
			resume_data: 'Résumé des données',
			no_literate: 'Pas alphabétisé',
			fundamental_incomplete: 'Fondamental - Incomplet',
			fundamental_complete: 'Fondamental - Complet',
			middle_incomplete: 'Moyen - Incomplet',
			middle_complete: 'Moyen - Complet',
			superior_incomplete: 'Incomplet supérieur',
			superior_complete: 'Diplômé',
			pos_incomplete: 'Lato sensu - Incomplet',
			pos_complete: 'Lato sensu - Complet',
			master_incomplete: 'Master - Incomplet',
			master_complete: 'Maîtrise - Complète',
			doctorate_incomplete: 'Doctorat - Incomplet',
			doctorate_complete: 'Doctorat - Complet',
			invalid_contact: 'Format de contact invalide',
			obs: 'Commentaires',
			allRightsReserved: 'Tous les droits sont réservés.',
			want_now_more_about: 'Je veux en savoir plus sur le',
			no_repeat: 'Les données ne peuvent pas être répétées',
			personal_pcva: 'Programme Maison verte et jaune',
			compRenda: 'Type de preuve de revenu',
			funcPublico: "C'est un agent public,",
			decImpRenda: "Déclarer l'impôt sur le revenu,",
			tempoCarteira: 'Depuis combien de temps avez-vous une inscription dans votre portefeuille',
			rendaIndividual: 'Revenu Brut Individuel',
			comporRenda: "Allez composer de la dentelle avec quelqu'un,",
			valorRenda2: 'Quelle est la valeur du deuxième revenu',
			nascMaisVelhoRenda2: "Quelle est la date de naissance de l'aîné,",
			fgts: 'Montant de votre FGTS',
			entrada: "Est-ce qu'il y a un droit d'entrée ? Si OUI, quel montant,",
			restricao: 'Avez-vous une restriction sur votre nom ? (SPC/Serasa)',
			interesse: "Quelle est la région d'intérêt,",
			estadoInteresse: 'État',
			cidadeInteresse: 'Ville',
			bairroInteresse: 'Quartier',
			program: 'Programme',
			programs: 'Logiciel',
			privacy_policies: 'Politique de confidentialité',
			prefectures: 'Préfectures',
			prefecture: 'Mairie',
			fantasy: 'Fantaisie',
			legalNature: 'Nature Juridique',
			dev_econom: 'Développement économique',
			nis: 'PIS/NIS',
			nis_already_registered: 'PIS/NIS déjà enregistré',
			group: 'Groupe',
			created: 'Enregistré',
			person_found_in_other_prefecture: 'Personne déjà inscrite dans une autre commune',
			print: 'Imprimer',
			datas: 'Données',
			qualquer: 'Quelconque',
			dou_fe: 'Je confirme que les informations ci-dessus sont correctes.',

			change_indicant: 'Changement',
			password_security: 'Mot de passe de sécurité',
			confirm_password_security: 'Confirmer le mot de passe de sécurité',
			plan: 'plano',
			terms: "J'accepte les conditions d'utilisation",
			user_inactive: 'Utilisateur inactif',
		},
	},
};

export { messages };
