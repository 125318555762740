import React from 'react';

function Footer() {
	return (
		<React.Fragment>
			<footer className='bg-white rounded shadow p-5 mb-4 mt-4'>
				<div className='row'>
					<div className='col-12 col-md-4 col-xl-6 mb-4 mb-md-0'>
						<p className='mb-0 text-center text-lg-start'>
							© 2022-<span className='current-year'>{new Date().getFullYear()}</span>{' '}
							<a
								className='text-primary fw-normal'
								href='https://factorycode.com.br'
								target={'_blank'}
								rel='noopener noreferrer'>
								Factory Code
							</a>
						</p>
					</div>
					<div className='col-12 col-md-8 col-xl-6 text-center text-lg-start'>
						<ul className='list-inline list-group-flush list-group-borderless text-md-end mb-0'>
							<li className='list-inline-item px-0 px-sm-2'>
								<a href='https://factorycode.com.br/about' target={'_blank'} rel='noopener noreferrer'>
									About
								</a>
							</li>
							<li className='list-inline-item px-0 px-sm-2'>
								<a href='https://factorycode.com.br/contact' target={'_blank'} rel='noopener noreferrer'>
									Contacts
								</a>
							</li>
						</ul>
					</div>
				</div>
			</footer>
		</React.Fragment>
	);
}

export default Footer;
