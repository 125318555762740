const messages = {
	la: {
		translations: {
			all_departments: 'جميع الإدارات',
			about: 'معلومات عنا',
			above_the_same: 'أعلاه بالضبط نفس الشيء',
			action: 'فعل',
			ad: 'إعلان',
			add: 'يضيف',
			add_option: 'أضف الخيار',
			add_payment: 'أضف الدفع',
			add_question: 'أضف سؤالا',
			add_receipt: 'إضافة إيصال',
			address: 'تبوك',
			admin: 'إدارة',
			advance: 'يتقدم',
			affected: 'نجاح',
			air_transport: 'النقل الجوي',
			all: 'الجميع',
			allies_of: 'حلفاء',
			almost_there: 'اوشكت على الوصول',
			already_offset: 'أنا بالفعل تعويض',
			already_registered: 'هل هو مسجل بالفعل؟انقر هنا وبين',
			and: 'ه',
			and_more: 'و اكثر',
			approve: 'يعتمد',
			asc: 'تصاعدي',
			awaiting_email_confirmation: 'في انتظار تأكيد البريد الإلكتروني!',
			back: 'عد',
			balance: 'توازن',
			balance_avaliable: 'التوازن المتاح',
			banned: 'محظور',
			before_contact: 'قبل الاتصال بنا',
			binary: 'الثنائية',
			birth: 'ولادة',
			blind_man: 'انعكاس، ارتداد، انقلاب',
			but_possible_register:
				'Mesmo assim é possível fazer o seu cadastro, porém não ficará relacionado a nenhum amigo indicante.',
			buyer: 'مشتر',
			by: 'ل',
			bye: 'أراك لاحقًا',
			call_to: 'دعوة ل',
			canceled: 'ألغيت',
			calls: 'لذلك -يطلق عليهم',
			cancel: 'يلغي',
			cash_payment: 'دفع نقدا',
			cash_payment_return: 'إرجاع الدفع النقدي',
			cash_receipt: 'فاتورة الدفع النقدي',
			check_payment: 'الدفع في الشيكات',
			check_payment_return: 'إرجاع الدفع عند الشيك',
			check_receipt: 'تحقق من الإيصال',
			choose: 'يختار',
			city: 'مدينة',
			claims: 'المطالبات',
			click_and_know: 'انقر ومعرفة',
			click_to_copy_the_wallet: 'انقر لنسخ المحفظة',
			client: 'عميل',
			clients: 'عملاء',
			cnh: 'CNH (التأهيل)',
			cnpj: 'cnpj',
			cnpj_invalid: 'CNPJ غير صالح',
			color: 'كور',
			commercial: 'تجاري',
			company: 'اسم الشركة',
			complement: 'اطراء',
			confirm_approve: 'تأكيد الموافقة',
			confirm_delete: 'تأكيد الاستبعاد',
			confirm_new_password: 'قم بتأكيد كلمة مرور جديدة',
			confirm_password: 'قم بتأكيد كلمة مرور الوصول',
			confirmed: 'مؤكد',
			confirmed_email: 'البريد الإلكتروني المؤكد',
			contact: 'اتصال',
			contract: 'لمدة',
			copy: 'ينسخ',
			copy_success: 'نسخ بنجاح!',
			correspondences: 'المراسلات',
			country: 'دولة',
			cpf: 'cpf',
			cpf_invalid: 'CPF غير صالح',
			create_order_success: 'طلب تم إنشاؤه بنجاح!',
			cred: 'ائتمان',
			cred_deb: 'الاعتمادات/الديون',
			credit_card_payment: 'دفع بطاقة الائتمان',
			credit_card_payment_return: 'إرجاع دفع بطاقة الائتمان',
			credit_card_receipt: 'إيصال بطاقة الائتمان',
			current: 'حاضِر',
			current_password: 'كلمة المرور الحالي',
			dashboard: 'لوحة التحكم',
			date: 'بيانات',
			date_first_installment: 'تاريخ الدفع الأول',
			debit_card_payment: 'دفع بطاقة الخصم',
			debit_card_payment_return: 'إرجاع الدفع على بطاقة الخصم',
			debit_card_receipt: 'إيصال بطاقة الديون',
			debt: 'دَين',
			deleted_document: 'تم استبعاد المستند من التسجيل الخاص بك',
			desc: 'أسفل',
			description: 'وصف',
			details: 'تفاصيل',
			developer: 'مطور',
			directly_indicated: 'المشار إليها مباشرة',
			directly_indicateds: 'المشار إليها مباشرة',
			district: 'الحي / الحي',
			document: 'وثيقة',
			document_already_registered: 'وثيقة مسجلة بالفعل',
			documents: 'وثيقة',
			donate: 'فقط',
			donor: 'جهات مانحة',
			dont_know_zip: 'ألا تعرف الرمز البريدي؟انقر هنا',
			downloads: 'تحميل ملفات',
			drop_images: 'انقر فوق أو اسحب الصور هنا',
			due_date: 'نضج',
			edit: 'للتعديل',
			edit_option: 'تحرير الخيار',
			edit_question: 'إصدار سؤال',
			email: 'بريد إلكتروني',
			email_already_registered: 'البريد الإلكتروني مسجل مسبقا',
			email_and_or_password_wrong: 'البريد الإلكتروني و/أو كلمة المرور خاطئة!',
			user_and_or_password_wrong: 'مستخدم و/أو كلمة مرور خاطئ!',
			email_for_login: 'هذا البريد الإلكتروني سيكون تسجيل الدخول الخاص بك',
			email_or_password_wrong: 'بريد أو كلمة مرورغير صحيحة',
			user_or_password_wrong: 'اسم المستخدم أو كلمة المرور غير صحيحة',
			employee: 'موظف',
			employees: 'الموظفين',
			error_confirm_password: 'يجب أن تكون التأكيد وكلمة المرور الجديدة متساوية',
			error_email: 'تنسيق بريد إلكتروني غير صالح',
			error_password: 'كلمة سر خاطئة',
			error_phone: 'رقم الهاتف غير صحيح',
			exchange: 'تبادل',
			expired: 'منتهية الصلاحية',
			extra: 'إضافي',
			extracts: 'مقتطفات',
			feedback: 'يعود',
			final: 'أخير',
			financial: 'مالي',
			financial_payment: 'الدفع المالي',
			financial_payment_return: 'عودة الدفع المالي',
			financial_receipt: 'إيصال مالي',
			financing: 'التمويل',
			financings: 'التمويل',
			first_10_pages: 'أول 10 صفحات',
			for_year: 'بالسنة',
			forget_password: 'لقد نسيت كلمة المرور',
			forgot_password: 'هل نسيت كلمة السر',
			form: 'استمارة',
			form_of_payment: 'شكل الدفع',
			found: 'وجد',
			founds: 'وجد',
			friend_indicated: 'أشار أصدقائي',
			friend_indicateds: 'رشحه أصدقائي',
			fuel: 'الوقود',
			full_name: 'الاسم بالكامل',
			grand_total: 'المجموع الإجمالي',
			home: 'بداية',
			how_to_call: 'كيف تريد أن تسمى',
			images: 'الصور',
			in_order: 'مرتب',
			in_stock: 'في المخزن',
			indicant: 'مشيرا',
			indicated_by: 'يتم الإشارة إلى أنك',
			indicated_not_found: 'لم يتم العثور على الإشارة المستنيرة ...',
			indicateds: 'تم ترشيحه',
			info: 'غنيا بالمعلومات',
			info_contact: 'معلومات للتواصل',
			initial: 'أولي',
			insert_new: 'أدخل نوفو',
			installments: 'أقساط',
			invalid_images: 'صور غير صالحة',
			invalid_token: 'رمز غير صالح',
			invite: 'للدعوة',
			invite_friends: 'اعزم أصحابك',
			know_exact_result: 'هل تعرف القيمة الدقيقة؟',
			learn_more: 'تعرف أكثر',
			link_email: 'انقر على الرابط المرسلة إلى البريد الإلكتروني المسجل!',
			link_invite_copy: 'رابط المشاركة الناجح نسخ',
			liquid: 'سائل',
			loading: 'جار التحميل',
			login: 'للدخول',
			logout: 'غادر',
			manage_users: 'ادارة المستخدمين',
			manager: 'مدير',
			manufacturer: 'الصانع',
			market: 'ميركادو',
			message: 'رسالة',
			mission: 'بعثة',
			mission_vision_values: 'المهمة والرؤية والقيم',
			model: 'نموذج',
			movements: 'الحركات',
			my_account: 'حسابي',
			my_wallet: 'محفظتي',
			mystock: 'مخزون بلدي',
			name: 'اسم',
			nature: 'طبيعة سجية',
			new: 'نوفو',
			new_financing: 'تمويل جديد',
			new_password: 'كلمة سر جديدة',
			new_seller: 'بائع جديد',
			news: 'أخبار',
			next: 'التالي',
			nick: 'المستعمل',
			no: 'لا',
			no_approve: 'لا يوافق بعد',
			no_delete: 'لا تستبعد',
			no_results_found: 'لم يتم العثور على نتائج',
			no_services_or_products_added: 'لم يتم إضافة خدمة أو منتج',
			none_friends_indicated: 'لم أشير إلى أي أصدقاء بعد',
			not_authorized: 'غير مخول',
			not_found: 'لم يتم العثور على',
			not_login: 'لم يكن من الممكن الدخول ، والتحقق من البيانات وحاول مرة أخرى!',
			not_orders: 'لا أوامر الدفع',
			not_registered: 'أليست مسجلة بعد؟انقر هنا وتسجيل',
			not_results: 'لا توجد سجلات',
			not_self_register: 'لم يكن من الممكن التسجيل',
			not_today_entry: 'لم يكن لدينا مداخل حتى الآن اليوم',
			notfound_ad: 'لم يتم العثور على إعلانات',
			number: 'رقم',
			of: 'من',
			at: 'م',
			off: 'إيقاف',
			on_total: 'من مجموع',
			open: 'يفتح',
			opening: 'افتتاح',
			operation: 'أداء',
			ordenation: 'ترتيب',
			order: 'ترتيب',
			orders: 'الطلبات',
			other: 'آخر',
			other_services_or_products: 'الخدمات أو المنتجات الأخرى',
			paid: 'أنا أدفع',
			paid_date: 'يوم الدفع',
			partial: 'دفع جزئي',
			passport: 'جواز سفر',
			password: 'كلمة المرور',
			pay: 'دفع',
			pay_value_in: 'دفع القيمة في',
			payable: 'دفع',
			payer: 'دافع',
			payment: 'قسط',
			pendent: 'قيد الانتظار',
			personal_data: 'بيانات شخصية',
			prefecture_data: 'بيانات قاعة المدينة',
			phone: 'فون',
			pix: 'بيكس',
			pix_payment: 'دفع الألم',
			pix_payment_return: 'عودة دفع الألم',
			pix_receipt: 'إيصال في PIX',
			plates: 'علامات',
			please_wait: 'أرجو الإنتظار',
			plus: 'زائد',
			prev: 'الأمامي',
			price: 'السعر',
			profile: 'حساب تعريفي',
			project: 'المشروع',
			projects: 'المشاريع',
			purchase: 'شراء',
			quantity: 'المبلغ',
			quotation: 'السعر',
			receivable: 'تسلم',
			recipient: 'متلقي',
			recommendations: 'التوصيات',
			redefine_password: 'إعادة تعريف كلمة المرور',
			register: 'السجل',
			registers: 'السجلات',
			registers_users: 'تسجيلات المستخدم',
			reject: 'يرفض',
			remaining: 'متبقي',
			required_data_not_sent: 'البيانات اللازمة لم يتم إرسالها',
			resend_email: 'مراجعة البريد الإلكتروني',
			residential: 'سكني',
			result: 'نتيجة',
			results: 'نتائج',
			revoked: 'إلغاء - فسخ',
			rg: 'التسجيل العام (الهوية)',
			roi: 'مكاسب يومية',
			sale: 'تخفيض السعر',
			save: 'للحفظ',
			search: 'بحث',
			secretary: 'سكرتير',
			see_advents: 'انظر الإعلانات',
			see_details: 'انظر التفاصيل',
			see_details_wallet: 'انظر تفاصيل المحفظة',
			see_financing: 'عرض التمويل',
			see_order: 'انظر النظام',
			see_user: 'انظر المستخدمين',
			select_vehicle: 'حدد السيارة',
			self_register: 'يسجل',
			seller: 'بائع',
			send_to: 'ارسل إلى',
			settings: 'إعدادات',
			share_your_link: 'شارك الرابط الخاص بك',
			show: 'الحارس يصل',
			show_from: 'عرض من',
			sign_in_to_start_your_session: 'اجعل تسجيل الدخول لبدء جلستك',
			sign_up: 'يسجل',
			sold: 'مُباع',
			state: 'حالة',
			status: 'حالة',
			street: 'اثنين',
			subject: 'الموضوع',
			submit: 'لترسل',
			submit_placeholder: 'يرجى ملء البريد الإلكتروني الخاص بك',
			subscribe: 'اشترك لتلقي أخبارنا',
			subtitle: 'شرح',
			success_update_user: 'تم تحديث المستخدم بنجاح!',
			suggestions: 'اقتراحات',
			supervisor: 'مشرف',
			support: 'الدعم',
			suspended: 'موقوف عن العمل',
			this_value_in: 'هذه القيمة في',
			title: 'لقب',
			to: 'حتى',
			to_pay: 'اطفئه',
			to_receive: 'القبض',
			today_entry: 'دخول اليوم',
			token_not_found_or_already_used: 'لم يتم العثور على الرمز المميز أو تم استخدامه',
			total: 'مجموع',
			total_entry: 'إجمالي الدخول',
			transfer_payment: 'تحويل الدفع',
			transfer_payment_return: 'تحويل الدفع',
			transfer_receipt: 'إيصال التحويل',
			type: 'يكتب',
			update: 'للتحديث',
			used: 'مستخدم',
			user: 'المستعمل',
			user_already_exists: 'المستخدم موجود اصلا!',
			user_not_found: 'لم يتم العثور على المستخدم',
			user_not_logged: 'لم يتم تسجيل الدخول إلى المستخدم',
			users: 'المستخدمون',
			users_list: 'قائمة المستخدمين',
			list: 'قائمة',
			valid_images: 'صور صالحة',
			value: 'شجاعة',
			value_donate_on: 'قيمة التبرع في',
			value_financed: 'القيمة الممولة',
			value_installment: 'قيمة التقسيط',
			values: 'قيم',
			vehicle: 'مركبة',
			vehicles: 'مركبات',
			view_order: 'انظر النظام',
			vision: 'بصر',
			visitor: 'زائر',
			voucher: 'فاتورة',
			wait_confirm: 'تأكيد الانتظار',
			waiting: 'منتظر',
			waiting_email: 'تم إجراء التسجيل بنجاح ، الآن تأكيد البريد الإلكتروني فقط.',
			waiting_payment: 'انتظار الدفع',
			wallet_generate: 'محفظة نقود',
			want_donate: 'اريد التبرع',
			we_are: 'نكون',
			welcome: 'أهلا بك',
			who_we_are: 'من نحن',
			who_we_are_subtitle: 'وصف موجز ل',
			will_expire_in: 'سوف تنتهي في',
			with: 'كوم',
			year: 'حتى الآن',
			years: 'سنين',
			yes: 'سيم',
			your_dashboard: 'هذه هي لوحة التحكم الخاصة بك.',
			zipcode: 'cep',
			January: 'يناير',
			February: 'شهر فبراير',
			March: 'يمشي',
			April: 'أبريل',
			May: 'قد',
			June: 'يونيه',
			July: 'يوليو',
			August: 'أغسطس',
			September: 'سبتمبر',
			October: 'اكتوبر',
			November: 'شهر نوفمبر',
			December: 'ديسمبر',
			location: 'موقع',
			portfolio: 'مَلَفّ',
			how_it_works: 'كيف تعمل',
			public_works: 'الأشغال العامة',
			private_works: 'الأعمال الخاصة',
			a_crase: 'الى',
			ao: 'ل',
			lead: 'سنقوم معًا ببناء مستقبل جديد.',
			system: 'نظام',
			not_permission: 'لا يُسمح لك بالوصول إلى هذه الصفحة',
			in_construction: 'تحت التشيد',
			reports: 'تقارير',
			transparency: 'الشفافية',
			casa_verde_amarela: 'البيت الأخضر الأصفر',
			people: 'الناس',
			person: 'شخص',
			register_people: 'تسجيل الناس',
			fisica: 'الشخص المادي',
			juridica: 'شخص قانوني',
			clean: 'ينظف',
			personal_infos: 'معلومة',
			prefecture_infos: 'معلومة',
			nationality: 'الجنسية (البلد الذي ولدت فيه)',
			naturalness: 'الطبيعية (المدينة التي ولدت فيها)',
			rgAgency: 'RG باعث',
			rgUf: 'RG',
			rgDate: 'تاريخ إصدار RG',
			cnhDate: 'تاريخ الإصدار CNH',
			gender: 'النوع',
			schooling: 'التعليم',
			profession: 'مهنة',
			maritalStatus: 'الحالة المدنية',
			motherName: 'اسم الأم',
			fatherName: 'اسم الاب',
			dependents: 'المعالين',
			male: 'مذكر',
			feminine: 'أنثى',
			single: 'غير متزوج',
			married: 'متزوج',
			widower: 'أرمل',
			divorced: 'مُطلّق',
			personal_addresses: 'يتصدر',
			prefecture_addresses: 'يتصدر',
			remove: 'مزيل',
			personal_contacts: 'جهات الاتصال',
			prefecture_contacts: 'جهات الاتصال',
			field_required: 'الانتهاء الإلزامي',
			data_saved: 'البيانات المحفوظة',
			data_added: 'البيانات المضافة',
			resume_data: 'ملخص البيانات',

			no_literate: 'غير مدروس',
			fundamental_incomplete: 'أساسي - غير مكتمل',
			fundamental_complete: 'أساسية - كاملة',
			middle_incomplete: 'الأوسط - غير مكتمل',
			middle_complete: 'الأوسط - كاملة',
			superior_incomplete: 'متفوقة - غير مكتملة',
			superior_complete: 'متفوقة - كاملة',
			pos_incomplete: 'الجانب الجانبي - غير مكتمل',
			pos_complete: 'الجانب الجانبي - كامل',
			master_incomplete: 'ماجستير - غير مكتمل',
			master_complete: 'ماجستير - أكمل',
			doctorate_incomplete: 'الدكتوراه - غير مكتملة',
			doctorate_complete: 'الدكتوراه - كاملة',

			invalid_contact: 'تنسيق جهة اتصال غير صالح',
			obs: 'تعليقات',
			allRightsReserved: 'كل الحقوق محفوظة.',
			want_now_more_about: 'اريد ان اعرف المزيد عن',
			no_repeat: 'لا يمكن تكرار البيانات',

			personal_pcva: 'كاسا فيردي وبرنامج صفراء',
			compRenda: 'نوع إثبات الدخل',
			funcPublico: 'إنه موظف مدني',
			decImpRenda: 'يعلن ضريبة الدخل',
			tempoCarteira: 'كم من الوقت يسجل في محفظة',
			rendaIndividual: 'الدخل الإجمالي الفردي',
			comporRenda: 'سوف يؤلف الدخل مع شخص ما',
			valorRenda2: 'ما هي قيمة الدخل الثاني',
			nascMaisVelhoRenda2: 'ما هو تاريخ ميلاد الأكبر',
			fgts: 'قيمة FGTS الخاصة بك',
			entrada: 'هل لديك قيمة إدخال؟إذا كان الأمر كذلك ، فما القيمة',
			restricao: 'هل لديك قيود نيابة عنك؟(SPC/Serasa)',
			interesse: 'ما هي منطقة الاهتمام',
			estadoInteresse: 'حالة',
			cidadeInteresse: 'مدينة',
			bairroInteresse: 'حيّ',
			program: 'برنامج',
			programs: 'برمجة',
			privacy_policies: 'سياسات الخصوصية',
			prefectures: 'البلديات',
			prefecture: 'قاعة المدينة',
			fantasy: 'فانتازيا',
			legalNature: 'الطبيعة القانونية',
			dev_econom: 'النمو الإقتصادي',
			nis: 'pis/nis',
			nis_already_registered: 'PIS/NIS مسجل بالفعل',
			group: 'مجموعة',
			created: 'مسجل في',
			person_found_in_other_prefecture: 'شخص مسجل بالفعل في بلدية أخرى',
			print: 'مطبعة',
			datas: 'بيانات',
			qualquer: 'أي',
			dou_fe: 'أؤكد أن المعلومات أعلاه صحيحة.',

			change_indicant: 'يتغيرون',
			password_security: 'كلمة مرور السلامة',
			confirm_password_security: 'قم بتأكيد كلمة مرور الأمان',
			plan: 'بلانو',
			terms: 'أقبل شروط الاستخدام',
			user_inactive: 'مستخدم غير نشط',
		},
	},
};

export { messages };
