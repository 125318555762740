/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from 'react';
import './Dashboard.css';

/**
 * props:
 * - symbol
 */
function CandleChart(props) {
	// eslint-disable-next-line
	const [widget, setWidget] = useState({});

	useEffect(() => {
		const w = new window.TradingView.widget({
			symbol: `BINANCE:${props.symbol}`,
			autosize: true,
			interval: '1',
			timezone: 'America/Sao_Paulo',
			theme: 'dark',
			style: '1',
			locale: 'en',
			hide_side_toolbar: false,
			toolbar_bg: '#f1f3f6',
			enable_publishing: false,
			allow_symbol_change: false,
			withdateranges: true,
			details: true,
			studies_overrides: {
				'relative strength index.rsi.color': '#ffff00',
				'relative strength index.upper band.color': '#ffffff',
				'relative strength index.lower band.color': '#ffffff',
				'relative strength index.upper band.value': 60,
				'relative strength index.lower band.value': 40,
			},
			studies: [
				{
					id: 'RSI@tv-basicstudies',
					inputs: { length: 14 },
				},
				{
					id: 'VSTOP@tv-basicstudies',
					inputs: { length: 100, multiplier: 1, timeframe: 'chart' },
				},
				/* { id: "MF@tv-basicstudies", inputs: { length: 5 }, bands: { 0: { color: "#c0c0c0", linestyle: 2, linewidth: 1, value: 90, visible: true, name: "Overbought" }, 1: { color: "#c0c0c0", linestyle: 2, linewidth: 1, value: 10, visible: true, name: "Oversold" }, }, }, { id: "MAExp@tv-basicstudies", inputs: { length: 8, source: "close", }, styles: { MovAvgExp: { color: "rgba(255,252,0,1)", }, }, }, { id: "MAExp@tv-basicstudies", inputs: { length: 80, source: "close", }, styles: { MovAvgExp: { color: "rgba(0, 188, 212, 1)", }, }, }, */
			],
			container_id: 'tradingview',
		});
		setWidget(w);
	}, [props.symbol]);

	const widgetHtml = useMemo(() => {
		return (
			<div className='row'>
				<div className='col-12 mb-4'>
					<div className='card cardDark border-0 shadow'>
						<div className='card-body p-2'>
							<div className='tradingview-widget-container '>
								<div id='tradingview' className='divTradingView'></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}, [props.symbol]);

	return widgetHtml;
}

export default CandleChart;
