const messages = {
	de: {
		translations: {
			all_departments: 'Alle Abteilungen',
			about: 'Über uns',
			above_the_same: 'Über genau gleich',
			action: 'Handlung',
			ad: 'Bekanntmachung',
			add: 'Hinzufügen',
			add_option: 'Option hinzufügen',
			add_payment: 'Zahlung hinzufügen',
			add_question: 'Frage hinzufügen',
			add_receipt: 'Quittung hinzufügen',
			address: 'Die Anschrift',
			admin: 'Verwaltung',
			advance: 'Vorauszahlung',
			affected: 'Schlagen',
			air_transport: 'Lufttransport',
			all: 'Alle',
			allies_of: 'Verbündete von',
			almost_there: 'Fast dort',
			already_offset: 'Ich habe bereits entschädigt',
			already_registered: 'Ist es bereits registriert?Klicken Sie hier und dazwischen',
			and: 'e',
			and_more: 'und mehr',
			approve: 'Genehmigen',
			asc: 'Aufsteigend',
			awaiting_email_confirmation: 'Warten auf Bestätigung der E -Mail!',
			back: 'Komm zurück',
			balance: 'Balance',
			balance_avaliable: 'Gleichgewicht verfügbar',
			banned: 'Verboten',
			before_contact: 'Bevor Sie uns kontaktieren',
			binary: 'Binär',
			birth: 'Geburt',
			blind_man: 'Umkehrung',
			but_possible_register:
				'Mesmo assim é possível fazer o seu cadastro, porém não ficará relacionado a nenhum amigo indicante.',
			buyer: 'Käufer',
			by: 'Pro',
			bye: 'Bis später',
			call_to: 'Ruf an',
			canceled: 'Abgebrochen',
			calls: 'Sogenannt',
			cancel: 'Absagen',
			cash_payment: 'Barzahlung',
			cash_payment_return: 'Rückgabe der Barzahlung',
			cash_receipt: 'Bargeldquittung',
			check_payment: 'Zahlung in Schach',
			check_payment_return: 'Zahlungsrendite auf den Scheck',
			check_receipt: 'Überprüfen Sie die Quittung',
			choose: 'Wählen',
			city: 'Stadt',
			claims: 'Ansprüche',
			click_and_know: 'Klicken und wissen',
			click_to_copy_the_wallet: 'Klicken Sie hier, um die Brieftasche zu kopieren',
			client: 'Klient',
			clients: 'Kunden',
			cnh: 'CNH (Qualifikation)',
			cnpj: 'Cnpj',
			cnpj_invalid: 'Cnpj ungültig',
			color: 'Korb',
			commercial: 'Kommerziell',
			company: 'Name der Firma',
			complement: 'Ergänzen',
			confirm_approve: 'Genehmigung bestätigen',
			confirm_delete: 'Ausgrenzung bestätigen',
			confirm_new_password: 'Bestätigen Sie ein neues Passwort',
			confirm_password: 'Bestätigen Sie das Access -Passwort',
			confirmed: 'Bestätigt',
			confirmed_email: 'Bestätigte E -Mail',
			contact: 'Kontakt',
			contract: 'Vertrag',
			copy: 'Kopieren',
			copy_success: 'Erfolgreich kopiert!',
			correspondences: 'Korrespondenzen',
			country: 'Land',
			cpf: 'cpf',
			cpf_invalid: 'Ungültiges CPF',
			create_order_success: 'Anfrage erfolgreich erstellt!',
			cred: 'Kredit',
			cred_deb: 'Gutschriften/Schulden',
			credit_card_payment: 'Kreditkarten Zahlung',
			credit_card_payment_return: 'Kreditkartenzahlungsrückgabe',
			credit_card_receipt: 'Kreditkarteneingang',
			current: 'Aktuell',
			current_password: 'Derzeitiges Passwort',
			dashboard: 'Schalttafel',
			date: 'Daten',
			date_first_installment: 'Erster Zahlungsdatum',
			debit_card_payment: 'Debitkartenzahlung',
			debit_card_payment_return: 'Zahlungsrückgabe auf Debitkarte',
			debit_card_receipt: 'Schuldenkarteneingang',
			debt: 'Schulden',
			deleted_document: 'Das Dokument wurde von Ihrer Registrierung ausgeschlossen',
			desc: 'Nach unten',
			description: 'Beschreibung',
			details: 'Einzelheiten',
			developer: 'Entwickler',
			directly_indicated: 'Direkt angegeben',
			directly_indicateds: 'Direkt angezeigt',
			district: 'Nachbarschaft / Bezirk',
			document: 'Dokumentieren',
			document_already_registered: 'Dokument bereits registriert',
			documents: 'Dokumentieren',
			donate: 'Nur',
			donor: 'Spender',
			dont_know_zip: 'Kennst du die Postleitzahl nicht?Klicke hier',
			downloads: 'Dateien herunterladen',
			drop_images: 'Klicken oder ziehen Sie die Bilder hier',
			due_date: 'Reife',
			edit: 'Bearbeiten',
			edit_option: 'Option bearbeiten',
			edit_question: 'Eine Frage geben',
			email: 'email',
			email_already_registered: 'Email schon registriert',
			email_and_or_password_wrong: 'E -Mail und/oder Passwort falsch!',
			user_and_or_password_wrong: 'Falscher Benutzer und/oder Passwort!',
			email_for_login: 'Diese E -Mail ist Ihr Login',
			email_or_password_wrong: 'falsche Email oder Passwort',
			user_or_password_wrong: 'falscher Benutzername oder Passwort',
			employee: 'Mitarbeiter',
			employees: 'Angestellte',
			error_confirm_password: 'Bestätigung und neues Passwort müssen gleich sein',
			error_email: 'ungültiges Email-Format',
			error_password: 'Falsches Passwort',
			error_phone: 'UNGÜLTIGE TELEFONNUMMER',
			exchange: 'Austausch',
			expired: 'Abgelaufen',
			extra: 'extra',
			extracts: 'Auszüge',
			feedback: 'Zurückkehren',
			final: 'Finale',
			financial: 'Finanziell',
			financial_payment: 'Finanzielle Zahlung',
			financial_payment_return: 'Rückgabe der finanziellen Zahlung',
			financial_receipt: 'Finanzieller Quittung',
			financing: 'Finanzierung',
			financings: 'Finanzierung',
			first_10_pages: 'Erste 10 Seiten',
			for_year: 'Pro Jahr',
			forget_password: 'Ich habe das Passwort vergessen',
			forgot_password: 'Passwort vergessen',
			form: 'Bilden',
			form_of_payment: 'Zahlungsart',
			found: 'Gefunden',
			founds: 'Gefunden',
			friend_indicated: 'Angezeigt von meinen Freunden',
			friend_indicateds: 'Nominiert von meinen Freunden',
			fuel: 'Kraftstoff',
			full_name: 'Vollständiger Name',
			grand_total: 'Gesamtsumme',
			home: 'Start',
			how_to_call: 'Wie du genannt werden willst',
			images: 'Bilder',
			in_order: 'In Ordnung',
			in_stock: 'Auf Lager',
			indicant: 'Anzeige',
			indicated_by: 'Sie werden angezeigt durch',
			indicated_not_found: 'Der informierte Anzeigen wurde nicht gefunden ...',
			indicateds: 'Nominiert',
			info: 'Informativ',
			info_contact: 'Kontakt Informationen',
			initial: 'Initial',
			insert_new: 'Geben Sie Novo ein',
			installments: 'Raten',
			invalid_images: 'Ungültige Bilder',
			invalid_token: 'Ungültiges Token',
			invite: 'Einladen',
			invite_friends: 'Lade deine Freunde ein',
			know_exact_result: 'Kennen Sie den genauen Wert?',
			learn_more: 'Mehr wissen',
			link_email: 'Klicken Sie auf den Link an die registrierte E -Mail gesendet!',
			link_invite_copy: 'Erfolgreicher Kopier -Sharing -Link',
			liquid: 'Flüssig',
			loading: 'Wird geladen',
			login: 'Betreten',
			logout: 'Verlassen',
			manage_users: 'Benutzer verwalten',
			manager: 'Manager',
			manufacturer: 'Hersteller',
			market: 'mercado',
			message: 'Nachricht',
			mission: 'Mission',
			mission_vision_values: 'Mission, Vision und Werte',
			model: 'Modell',
			movements: 'Bewegungen',
			my_account: 'Mein Konto',
			my_wallet: 'Mein Geldbeutel',
			mystock: 'Meine Aktie',
			name: 'Name',
			nature: 'Natur',
			new: 'novo',
			new_financing: 'Neue Finanzierung',
			new_password: 'Neues Passwort',
			new_seller: 'Neuer Verkäufer',
			news: 'Nachricht',
			next: 'Nächste',
			nick: 'Benutzer',
			no: 'Nicht',
			no_approve: 'Noch nicht genehmigen',
			no_delete: 'Nicht ausschließen',
			no_results_found: 'keine Ergebnisse gefunden',
			no_services_or_products_added: 'Keine Service oder Produkt hinzugefügt',
			none_friends_indicated: 'Ich habe noch keine Freunde angegeben',
			not_authorized: 'Nicht berechtigt',
			not_found: 'Nicht gefunden',
			not_login: 'Es war nicht möglich, die Daten einzugeben, die Daten zu überprüfen und erneut zu versuchen!',
			not_orders: 'Keine Zahlungsbestellungen',
			not_registered: 'Ist es noch nicht registriert?Klicken Sie hier und registrieren Sie sich',
			not_results: 'Keine Aufzeichnungen gefunden',
			not_self_register: 'Es war nicht möglich, sich zu registrieren',
			not_today_entry: 'Wir hatten heute noch keine Eingänge',
			notfound_ad: 'Keine Anzeigen gefunden',
			number: 'Anzahl',
			of: 'von',
			at: 'em',
			off: 'aus',
			on_total: 'Von der Gesamtheit von',
			open: 'Offen',
			opening: 'Öffnung',
			operation: 'Leistung',
			ordenation: 'Bestellung',
			order: 'Befehl',
			orders: 'Anfragen',
			other: 'Andere',
			other_services_or_products: 'Andere Dienstleistungen oder Produkte',
			paid: 'ich zahle',
			paid_date: 'Zahltag',
			partial: 'Teilweise Zahlung',
			passport: 'Reisepass',
			password: 'Passwort',
			pay: 'Zahlen',
			pay_value_in: 'Zahlen den Wert in',
			payable: 'Zahlen',
			payer: 'Zahler',
			payment: 'Zahlung',
			pendent: 'Ausstehend',
			personal_data: 'persönliche Daten',
			prefecture_data: 'Daten der Rathausdaten',
			phone: 'fone',
			pix: 'Pix',
			pix_payment: 'Schmerzzahlung',
			pix_payment_return: 'Schmerzzahlungsrendite',
			pix_receipt: 'Quittung in Pix',
			plates: 'Zeichen',
			please_wait: 'Warten Sie mal',
			plus: 'plus',
			prev: 'Vordere',
			price: 'Preis',
			profile: 'Profil',
			project: 'Projekt',
			projects: 'Projekte',
			purchase: 'Kaufen',
			quantity: 'Die Summe',
			quotation: 'Preis',
			receivable: 'Erhalten',
			recipient: 'Empfänger',
			recommendations: 'Empfehlungen',
			redefine_password: 'Passwort neu definieren',
			register: 'Registrierung',
			registers: 'Aufzeichnungen',
			registers_users: 'Benutzerregistrierungen',
			reject: 'Ablehnen',
			remaining: 'Verbleibend',
			required_data_not_sent: 'Notwendige Daten nicht gesendet',
			resend_email: 'Überprüfen Sie die E -Mail',
			residential: 'Wohn',
			result: 'Ergebnis',
			results: 'Ergebnisse',
			revoked: 'Widerrufen',
			rg: 'Allgemeine Registrierung (Identität)',
			roi: 'Tägliche Gewinne',
			sale: 'Verkauf',
			save: 'Speichern',
			search: 'Suchen',
			secretary: 'Sekretär',
			see_advents: 'Siehe die Anzeigen',
			see_details: 'siehe Einzelheiten',
			see_details_wallet: 'Siehe Brieftaschendetails',
			see_financing: 'Finanzierung anzeigen',
			see_order: 'Siehe Bestellung',
			see_user: 'Siehe Benutzer',
			select_vehicle: 'Wählen Sie das Fahrzeug aus',
			self_register: 'Registrieren',
			seller: 'Verkäufer',
			send_to: 'Senden an',
			settings: 'die Einstellungen',
			share_your_link: 'Teilen Sie Ihren Link',
			show: 'Reichweite',
			show_from: 'Anzeige von',
			sign_in_to_start_your_session: 'Machen Sie die Anmeldung, um Ihre Sitzung zu starten',
			sign_up: 'Registrieren',
			sold: 'Verkauft',
			state: 'Zustand',
			status: 'status',
			street: 'Zwei',
			subject: 'Betreff',
			submit: 'Senden',
			submit_placeholder: 'Bitte füllen Sie Ihre E -Mail aus',
			subscribe: 'Abonnieren Sie, um unsere Nachrichten zu erhalten',
			subtitle: 'Bildbeschriftung',
			success_update_user: 'Benutzer erfolgreich aktualisiert!',
			suggestions: 'Anregungen',
			supervisor: 'Supervisorin',
			support: 'Die Unterstützung',
			suspended: 'Suspendiert',
			this_value_in: 'Dieser Wert in',
			title: 'Titel',
			to: 'Bis um',
			to_pay: 'Schalte aus',
			to_receive: 'Forderung',
			today_entry: 'Der heutige Eintrag',
			token_not_found_or_already_used: 'Token nicht gefunden oder wurde verwendet',
			total: 'Gesamt',
			total_entry: 'Gesamteintrag',
			transfer_payment: 'Überweisung',
			transfer_payment_return: 'Zahlungsrendite überweisen',
			transfer_receipt: 'Überweisungsbeleg',
			type: 'Art',
			update: 'Aktualisieren',
			used: 'Gebraucht',
			user: 'Benutzer',
			user_already_exists: 'Benutzer existiert bereits!',
			user_not_found: 'Benutzer nicht gefunden',
			user_not_logged: 'Benutzer nicht angemeldet',
			users: 'Benutzer',
			users_list: 'Liste der Benutzer',
			list: 'Liste',
			valid_images: 'Gültige Bilder',
			value: 'Tapferkeit',
			value_donate_on: 'Spendenwert in',
			value_financed: 'Finanzierter Wert',
			value_installment: 'Ratenwert',
			values: 'Werte',
			vehicle: 'Fahrzeug',
			vehicles: 'Fahrzeuge',
			view_order: 'Siehe Bestellung',
			vision: 'Sehvermögen',
			visitor: 'Besucher',
			voucher: 'Gutschein',
			wait_confirm: 'Wartenbestätigung',
			waiting: 'Warten',
			waiting_email: 'Die Registrierung wurde erfolgreich erstellt, nun nur die E -Mail bestätigt.',
			waiting_payment: 'Warten auf die Zahlung',
			wallet_generate: 'Geldbörse',
			want_donate: 'Ich möchte spenden',
			we_are: 'Sind',
			welcome: 'Herzlich willkommen',
			who_we_are: 'Wer wir sind',
			who_we_are_subtitle: 'Eine kurze Beschreibung der',
			will_expire_in: 'wird in',
			with: 'com',
			year: 'Noch',
			years: 'Jahre',
			yes: 'sim',
			your_dashboard: 'Dies ist Ihr Bedienfeld.',
			zipcode: 'Cep',
			January: 'Januar',
			February: 'Februar',
			March: 'Marsch',
			April: 'April',
			May: 'Dürfen',
			June: 'Juni',
			July: 'Juli',
			August: 'August',
			September: 'September',
			October: 'Oktober',
			November: 'November',
			December: 'Dezember',
			location: 'Ort',
			portfolio: 'Portfolio',
			how_it_works: 'Wie es funktioniert',
			public_works: 'Öffentliche Bauarbeiten',
			private_works: 'Private Arbeiten',
			a_crase: 'bei',
			ao: 'zu',
			lead: 'Gemeinsam werden wir eine neue Zukunft aufbauen.',
			system: 'System',
			not_permission: 'Sie dürfen nicht auf diese Seite zugreifen',
			in_construction: 'Im Bau',
			reports: 'Berichte',
			transparency: 'Transparent',
			casa_verde_amarela: 'Gelbes Gewächshaus',
			people: 'Menschen',
			person: 'Person',
			register_people: 'Registrierung von Personen',
			fisica: 'Physische Person',
			juridica: 'Juristische Person',
			clean: 'Sauber',
			personal_infos: 'Information',
			prefecture_infos: 'Information',
			nationality: 'Nationalität (Land, in dem es geboren wurde)',
			naturalness: 'Natürlichkeit (Stadt, in der Sie geboren wurden)',
			rgAgency: 'RG -Emitter',
			rgUf: 'Rg',
			rgDate: 'RG Ausgabedatum',
			cnhDate: 'CNH -Ausgabedatum',
			gender: 'Genre',
			schooling: 'Schulung',
			profession: 'Beruf',
			maritalStatus: 'Zivilstand',
			motherName: 'Name der Mutter',
			fatherName: 'Name des Vaters',
			dependents: 'Angehörige',
			male: 'Männlich',
			feminine: 'Weiblich',
			single: 'Nicht verheiratet',
			married: 'Verheiratet',
			widower: 'Witwer',
			divorced: 'Geschieden',
			personal_addresses: 'Angeht',
			prefecture_addresses: 'Angeht',
			remove: 'Entferner',
			personal_contacts: 'Kontakte',
			prefecture_contacts: 'Kontakte',
			field_required: 'Obligatorische Fertigstellung',
			data_saved: 'Daten gespeicherte Daten',
			data_added: 'Daten hinzugefügt',
			resume_data: 'Datenzusammenfassung',

			no_literate: 'Nicht literat',
			fundamental_incomplete: 'Grundlegend - unvollständig',
			fundamental_complete: 'Grundlegend - vollständig',
			middle_incomplete: 'Mitte - unvollständig',
			middle_complete: 'Mitte - vollständig',
			superior_incomplete: 'Überlegen - unvollständig',
			superior_complete: 'Überlegen - vollständig',
			pos_incomplete: 'Seitenseite - unvollständig',
			pos_complete: 'Seitenseite - komplett',
			master_incomplete: 'Master - unvollständig',
			master_complete: 'Master - vollständig',
			doctorate_incomplete: 'Doktorat - unvollständig',
			doctorate_complete: 'Promotion - vollständig',

			invalid_contact: 'Ungültiges Kontaktformat',
			obs: 'Bemerkungen',
			allRightsReserved: 'Alle Rechte vorbehalten.',
			want_now_more_about: 'Ich möchte mehr darüber wissen',
			no_repeat: 'Daten können nicht wiederholt werden',

			personal_pcva: 'Casa Verde und gelbes Programm',
			compRenda: 'Art des Einkommensnachweises',
			funcPublico: 'Es ist ein Beamter',
			decImpRenda: 'Erklärt Einkommensteuer',
			tempoCarteira: 'Wie lange hat die Registrierung in einem Portfolio',
			rendaIndividual: 'Individuelles Bruttoeinkommen',
			comporRenda: 'Wird Einkommen mit jemandem zusammenstellen',
			valorRenda2: 'Was ist der Wert des zweiten Einkommens?',
			nascMaisVelhoRenda2: 'Was ist das Geburtsdatum der ältesten?',
			fgts: 'Wert Ihrer FGTs',
			entrada: 'Haben Sie Eingabewert?Wenn ja, welcher Wert',
			restricao: 'Haben Sie Einschränkungen in Ihrem Namen?(SPC/Serasa)',
			interesse: 'Was ist die Region von Interesse?',
			estadoInteresse: 'Zustand',
			cidadeInteresse: 'Stadt',
			bairroInteresse: 'Gegend',
			program: 'Programm',
			programs: 'Software',
			privacy_policies: 'Datenschutzrichtlinien',
			prefectures: 'Gemeinden',
			prefecture: 'Das Rathaus',
			fantasy: 'Fantasie',
			legalNature: 'Rechtliche Natur',
			dev_econom: 'Wirtschaftliche Entwicklung',
			nis: 'Pis/nis',
			nis_already_registered: 'PIS/NIS bereits registriert',
			group: 'Gruppe',
			created: 'Registriert in',
			person_found_in_other_prefecture: 'Person, die bereits in einer anderen Gemeinde registriert ist',
			print: 'Drucken',
			datas: 'Daten',
			qualquer: 'Beliebig',
			dou_fe: 'Ich bestätige, dass die obigen Informationen korrekt sind.',

			change_indicant: 'Ändern',
			password_security: 'Sicherheit Passwort',
			confirm_password_security: 'Bestätigen Sie das Sicherheitskennwort',
			plan: 'plano',
			terms: 'Ich akzeptiere die Nutzungsbedingungen',
			user_inactive: 'Inaktiver Benutzer',
		},
	},
};

export { messages };
