const messages = {
	cn: {
		translations: {
			all_departments: '所有部门',
			about: '关于我们',
			above_the_same: '超过完全相同',
			action: '行动',
			ad: '公告',
			add: '添加',
			add_option: '添加选项',
			add_payment: '添加付款',
			add_question: '添加问题',
			add_receipt: '添加收据',
			address: '地址',
			admin: '行政',
			advance: '进步',
			affected: '打',
			air_transport: '航空运输',
			all: '全部',
			allies_of: '盟友',
			almost_there: '快好了',
			already_offset: '我已经得到了补偿',
			already_registered: '它已经注册了吗？点击这里和之间',
			and: 'e',
			and_more: '和更多',
			approve: '批准',
			asc: '上升',
			awaiting_email_confirmation: '等待电子邮件确认！',
			back: '回来',
			balance: '平衡',
			balance_avaliable: '可用的余额',
			banned: '禁止',
			before_contact: '在您联系我们之前',
			binary: '二进制',
			birth: '出生',
			blind_man: '逆转',
			but_possible_register:
				'Mesmo assim é possível fazer o seu cadastro, porém não ficará relacionado a nenhum amigo indicante.',
			buyer: '买方',
			by: '为了',
			bye: '回头见',
			call_to: '拨电至',
			canceled: '取消',
			calls: '如此称呼',
			cancel: '取消',
			cash_payment: '现金支付',
			cash_payment_return: '现金付款退还',
			cash_receipt: '现金收据',
			check_payment: '支票付款',
			check_payment_return: '支票付款退货',
			check_receipt: '检查收据',
			choose: '选择',
			city: '城市',
			claims: '主张',
			click_and_know: '单击并知道',
			click_to_copy_the_wallet: '点击复制钱包',
			client: '客户',
			clients: '顾客',
			cnh: 'CNH（资格）',
			cnpj: 'cnpj',
			cnpj_invalid: 'CNPJ无效',
			color: 'cor',
			commercial: '商业的',
			company: '公司名',
			complement: '补充',
			confirm_approve: '确认批准',
			confirm_delete: '确认排除',
			confirm_new_password: '确认新密码',
			confirm_password: '确认访问密码',
			confirmed: '确认的',
			confirmed_email: '确认的电子邮件',
			contact: '接触',
			contract: '合同',
			copy: '复制',
			copy_success: '成功复制！',
			correspondences: '对应关系',
			country: '国家',
			cpf: 'cpf',
			cpf_invalid: '无效的CPF',
			create_order_success: '成功创建了请求！',
			cred: '信用',
			cred_deb: '积分/债务',
			credit_card_payment: '信用卡付款',
			credit_card_payment_return: '信用卡付款回报',
			credit_card_receipt: '信用卡收据',
			current: '当前的',
			current_password: '当前密码',
			dashboard: '控制面板',
			date: '数据',
			date_first_installment: '第一个付款日期',
			debit_card_payment: '借记卡付款',
			debit_card_payment_return: '借记卡付款回报',
			debit_card_receipt: '债务卡收据',
			debt: '债务',
			deleted_document: '文件被排除在您的注册之外',
			desc: '向下',
			description: '描述',
			details: '细节',
			developer: '开发人员',
			directly_indicated: '直接指示',
			directly_indicateds: '直接指示',
			district: '邻里 /地区',
			document: '文档',
			document_already_registered: '已经注册的文档',
			documents: '文档',
			donate: '只是',
			donor: '捐赠者',
			dont_know_zip: '您不知道邮政编码吗？点击这里',
			downloads: '下载文件',
			drop_images: '单击或在此处拖动图像',
			due_date: '到期',
			edit: '编辑',
			edit_option: '编辑选项',
			edit_question: '发出问题',
			email: '电子邮件',
			email_already_registered: '电子邮件已经注册',
			email_and_or_password_wrong: '电子邮件和/或密码错误！',
			user_and_or_password_wrong: '错误的用户和/或密码！',
			email_for_login: '此电子邮件将是您的登录',
			email_or_password_wrong: '错误的邮箱帐号或密码',
			user_or_password_wrong: '用户名或密码错误',
			employee: '员工',
			employees: '雇员',
			error_confirm_password: '确认和新密码必须平等',
			error_email: '无效的电子邮件格式',
			error_password: '密码错误',
			error_phone: '无效的电话号码',
			exchange: '交换',
			expired: '已到期',
			extra: '额外的',
			extracts: '提取物',
			feedback: '返回',
			final: '最终的',
			financial: '金融',
			financial_payment: '财务支付',
			financial_payment_return: '财务返还',
			financial_receipt: '财务收据',
			financing: '融资',
			financings: '融资',
			first_10_pages: '前10页',
			for_year: '每年',
			forget_password: '我忘记了密码',
			forgot_password: '忘记密码',
			form: '形式',
			form_of_payment: '付款形式',
			found: '成立',
			founds: '成立',
			friend_indicated: '由我的朋友指示',
			friend_indicateds: '由我的朋友提名',
			fuel: '燃料',
			full_name: '全名',
			grand_total: '累计',
			home: '开始',
			how_to_call: '你想如何被称为',
			images: '图片',
			in_order: '为了',
			in_stock: '有存货',
			indicant: '指示',
			indicated_by: '你被指示',
			indicated_not_found: '没有发现知情的表示...',
			indicateds: '提名',
			info: '内容丰富',
			info_contact: '联系信息',
			initial: '最初的',
			insert_new: '输入Novo',
			installments: '分期付款',
			invalid_images: '无效的图像',
			invalid_token: '令牌无效',
			invite: '邀请',
			invite_friends: '邀请你的朋友',
			know_exact_result: '您知道确切的价值吗？',
			learn_more: '知道更多',
			link_email: '单击发送到注册电子邮件的链接！',
			link_invite_copy: '成功复制共享链接',
			liquid: '液体',
			loading: '加载',
			login: '进入',
			logout: '离开',
			manage_users: '管理用户',
			manager: '经理',
			manufacturer: '制造商',
			market: 'mercado',
			message: '信息',
			mission: '使命',
			mission_vision_values: '使命，愿景和价值观',
			model: '模型',
			movements: '动作',
			my_account: '我的账户',
			my_wallet: '我的钱包',
			mystock: '我的股票',
			name: '名称',
			nature: '自然',
			new: 'novo',
			new_financing: '新融资',
			new_password: '新密码',
			new_seller: '新卖家',
			news: '消息',
			next: '下一个',
			nick: '用户',
			no: '不是',
			no_approve: '尚未批准',
			no_delete: '不要排除',
			no_results_found: '未找到结果',
			no_services_or_products_added: '没有添加的服务或产品',
			none_friends_indicated: '我还没有指出任何朋友',
			not_authorized: '未经授权',
			not_found: '未找到',
			not_login: '无法输入，检查数据并重试！',
			not_orders: '没有付款订单',
			not_registered: '还没有注册吗？单击此处并注册',
			not_results: '没有找到记录',
			not_self_register: '无法注册',
			not_today_entry: '我们今天还没有入口',
			notfound_ad: '找不到广告',
			number: '数字',
			of: '的',
			at: 'Em',
			off: '离开',
			on_total: '总计',
			open: '打开',
			opening: '开场',
			operation: '表现',
			ordenation: '订购',
			order: '命令',
			orders: '要求',
			other: '其他',
			other_services_or_products: '其他服务或产品',
			paid: '我付钱',
			paid_date: '付费日',
			partial: '部分付款',
			passport: '护照',
			password: '密码',
			pay: '支付',
			pay_value_in: '支付价值',
			payable: '支付',
			payer: '付款人',
			payment: '支付',
			pendent: '待办的',
			personal_data: '个人资料',
			prefecture_data: '市政厅数据',
			phone: '弹',
			pix: 'pix',
			pix_payment: '疼痛付款',
			pix_payment_return: '疼痛付款回报',
			pix_receipt: 'pix收据',
			plates: '标志',
			please_wait: '请稍等',
			plus: '加',
			prev: '前',
			price: '价格',
			profile: '轮廓',
			project: '项目',
			projects: '项目',
			purchase: '购买',
			quantity: '数量',
			quotation: '价格',
			receivable: '收到',
			recipient: '接受者',
			recommendations: '建议',
			redefine_password: '重新定义密码',
			register: '注册表',
			registers: '记录',
			registers_users: '用户注册',
			reject: '拒绝',
			remaining: '其余的',
			required_data_not_sent: '未发送的必要数据',
			resend_email: '查看电子邮件',
			residential: '住宅',
			result: '结果',
			results: '结果',
			revoked: '被撤销',
			rg: '一般注册（身份）',
			roi: '每日收益',
			sale: '销售',
			save: '保存',
			search: '寻找',
			secretary: '秘书',
			see_advents: '看到广告',
			see_details: '查看具体信息',
			see_details_wallet: '请参阅钱包细节',
			see_financing: '查看融资',
			see_order: '请参阅订单',
			see_user: '查看用户',
			select_vehicle: '选择车辆',
			self_register: '登记',
			seller: '推销员',
			send_to: '发送至',
			settings: '设置',
			share_your_link: '分享您的链接',
			show: '范围向上',
			show_from: '显示',
			sign_in_to_start_your_session: '登录以开始您的会话',
			sign_up: '登记',
			sold: '卖',
			state: '状态',
			status: '地位',
			street: '二',
			subject: '主题',
			submit: '发送',
			submit_placeholder: '请填写您的电子邮件',
			subscribe: '订阅收到我们的新闻',
			subtitle: '标题',
			success_update_user: '用户成功更新了！',
			suggestions: '建议',
			supervisor: '导师',
			support: '支持',
			suspended: '暂停',
			this_value_in: '此值IN',
			title: '标题',
			to: '直到',
			to_pay: '关',
			to_receive: '应收',
			today_entry: '今天的条目',
			token_not_found_or_already_used: '未找到或已使用的令牌',
			total: '全部的',
			total_entry: '总条件',
			transfer_payment: '转移付款',
			transfer_payment_return: '转移付款退货',
			transfer_receipt: '转移收据',
			type: '类型',
			update: '更新',
			used: '用过的',
			user: '用户',
			user_already_exists: '用户已存在！',
			user_not_found: '找不到用户',
			user_not_logged: '用户未登录',
			users: '用户',
			users_list: '用户列表',
			list: '列表',
			valid_images: '有效图像',
			value: '勇气',
			value_donate_on: '捐赠价值',
			value_financed: '资助价值',
			value_installment: '分期付款值',
			values: '值',
			vehicle: '车辆',
			vehicles: '汽车',
			view_order: '请参阅订单',
			vision: '眼力',
			visitor: '游客',
			voucher: '代金券',
			wait_confirm: '等待确认',
			waiting: '等待',
			waiting_email: '注册已成功进行，现在仅确认电子邮件。',
			waiting_payment: '等待付款',
			wallet_generate: '钱包',
			want_donate: '我想捐款',
			we_are: '是',
			welcome: '欢迎',
			who_we_are: '我们是谁',
			who_we_are_subtitle: '简要说明',
			will_expire_in: '将到期',
			with: 'com',
			year: '然而',
			years: '年',
			yes: 'SIM',
			your_dashboard: '这是您的控制面板。',
			zipcode: 'cep',
			January: '一月',
			February: '二月',
			March: '行进',
			April: '四月',
			May: '可能',
			June: '六月',
			July: '七月',
			August: '八月',
			September: '九月',
			October: '十月',
			November: '十一月',
			December: '十二月',
			location: '地点',
			portfolio: '文件夹',
			how_it_works: '怎么运行的',
			public_works: '公共工程',
			private_works: '私人作品',
			a_crase: '在',
			ao: '到',
			lead: '我们将共同建立新的未来。',
			system: '系统',
			not_permission: '您不允许访问此页面',
			in_construction: '正在建设中',
			reports: '报告',
			transparency: '透射',
			casa_verde_amarela: '黄色的温室',
			people: '人们',
			person: '人',
			register_people: '人民注册',
			fisica: '身体上的人',
			juridica: '法人',
			clean: '干净的',
			personal_infos: '信息',
			prefecture_infos: '信息',
			nationality: '国籍（它出生的国家）',
			naturalness: '自然（您出生的城市）',
			rgAgency: 'RG发射极',
			rgUf: 'RG',
			rgDate: 'RG发行日期',
			cnhDate: 'CNH发行日期',
			gender: '类型',
			schooling: '上学',
			profession: '职业',
			maritalStatus: '民事状况',
			motherName: '母亲的名字',
			fatherName: '父亲的名字',
			dependents: '家属',
			male: '男性',
			feminine: '女性',
			single: '没有结婚',
			married: '已婚',
			widower: '鳏夫',
			divorced: '离婚',
			personal_addresses: '对抗',
			prefecture_addresses: '对抗',
			remove: '去除剂',
			personal_contacts: '联系人',
			prefecture_contacts: '联系人',
			field_required: '强制完成',
			data_saved: '保存的数据',
			data_added: '添加了数据',
			resume_data: '数据摘要',

			no_literate: '非文字',
			fundamental_incomplete: '基本 - 不完整',
			fundamental_complete: '基本 - 完成',
			middle_incomplete: '中间 - 不完整',
			middle_complete: '中间 - 完成',
			superior_incomplete: '上级 - 不完整',
			superior_complete: '上级 - 完成',
			pos_incomplete: '侧面 - 不完整',
			pos_complete: '侧面 - 完成',
			master_incomplete: '主 - 不完整',
			master_complete: '主 - 完成',
			doctorate_incomplete: '博士学位 - 不完整',
			doctorate_complete: '博士学位 - 完成',

			invalid_contact: '无效的接触格式',
			obs: '注释',
			allRightsReserved: '版权所有。',
			want_now_more_about: '我想进一步了解',
			no_repeat: '数据无法重复',

			personal_pcva: 'CASA VERDE和黄色程序',
			compRenda: '收入证明类型',
			funcPublico: '这是公务员',
			decImpRenda: '宣布所得税',
			tempoCarteira: '注册在投资组合中有多长时间',
			rendaIndividual: '个人总收入',
			comporRenda: '将与某人赚钱',
			valorRenda2: '第二收入的价值是多少',
			nascMaisVelhoRenda2: '长子的出生日期是什么',
			fgts: 'fgts的价值',
			entrada: '您有输入值吗？如果是这样，什么价值',
			restricao: '您是否有限制？（SPC/Serasa）',
			interesse: '感兴趣的地区是什么',
			estadoInteresse: '状态',
			cidadeInteresse: '城市',
			bairroInteresse: '邻里',
			program: '程序',
			programs: '软件',
			privacy_policies: '隐私政策',
			prefectures: '市政当局',
			prefecture: '市政府',
			fantasy: '幻想曲',
			legalNature: '法律性质',
			dev_econom: '经济发展',
			nis: 'pis/nis',
			nis_already_registered: 'PIS/NIS已经注册',
			group: '团体',
			created: '注册',
			person_found_in_other_prefecture: '已经在另一个城市注册的人',
			print: '打印',
			datas: '数据',
			qualquer: '任何',
			dou_fe: '我确认上述信息是正确的。',

			change_indicant: '改变',
			password_security: '安全密码',
			confirm_password_security: '确认安全密码',
			plan: '普莱诺',
			terms: '我接受使用条款',
			user_inactive: '非活动用户',
		},
	},
};

export { messages };
