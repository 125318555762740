const messages = {
	es: {
		translations: {
			all_departments: 'Todos los departamentos',
			about: 'Sobre nosotros',
			above_the_same: 'Arriba exactamente lo mismo',
			action: 'Acción',
			ad: 'Anuncio',
			add: 'Agregar',
			add_option: 'Añadir opción',
			add_payment: 'Añadir Pago',
			add_question: 'Añadir pregunta',
			add_receipt: 'Agregar recibo',
			address: 'Dirección',
			admin: 'Administración',
			advance: 'Avance',
			affected: 'Alcanzó',
			air_transport: 'Transporte aereo',
			all: 'Todas',
			allies_of: 'Aliados de',
			almost_there: 'Casi allí',
			already_offset: 'Ya he compensado',
			already_registered: '¿Ya estás registrado? Haz clic aquí y entra',
			and: 'y',
			and_more: 'y más',
			approve: 'Aprobar',
			asc: 'Ascendente',
			awaiting_email_confirmation: '¡Esperando confirmación por correo electrónico!',
			back: 'Vuelve',
			balance: 'Balance',
			balance_avaliable: 'Saldo disponible',
			banned: 'Prohibido',
			before_contact: 'Antes de contactarnos',
			binary: 'Binario',
			birth: 'Nacimiento',
			blind_man: 'invertido',
			but_possible_register: 'Aún así, es posible registrarse, pero no se relacionará con ningún amigo referente.,',
			buyer: 'Comprador',
			by: 'por',
			bye: 'Hasta luego',
			call_to: 'Llamar a',
			canceled: 'Cancelado',
			calls: 'Llamó',
			cancel: 'Cancelar',
			cash_payment: 'Pago en dinero',
			cash_payment_return: 'Devolución de pago en efectivo',
			cash_receipt: 'recibo de caja',
			check_payment: 'Pago en cheque',
			check_payment_return: 'Devolución de pago mediante cheque',
			check_receipt: 'Recibo por cheque',
			choose: 'Escoger',
			city: 'Ciudad',
			claims: 'Reclamación (es',
			click_and_know: 'Haz clic y descubre',
			click_to_copy_the_wallet: 'Haga clic para copiar billetera',
			client: 'Cliente',
			clients: 'Clientes',
			cnh: 'CNH (Habilitación)',
			cnpj: 'CNPJ',
			cnpj_invalid: 'CNPJ no válido',
			color: 'Color',
			commercial: 'Comercial',
			company: 'Nombre de la empresa',
			complement: 'Complementar',
			confirm_approve: 'Confirmar Aprobación',
			confirm_delete: 'Confirmar la eliminación',
			confirm_new_password: 'Confirmar nueva contraseña',
			confirm_password: 'Confirmar Contraseña de Acceso',
			confirmed: 'Confirmado',
			confirmed_email: 'Correo electrónico confirmado',
			contact: 'Contacto',
			contract: 'Contrato',
			copy: 'Copiar',
			copy_success: '¡Copiado con éxito!',
			correspondences: 'Correspondencia',
			country: 'País',
			cpf: 'CPF',
			cpf_invalid: 'CPF no válido',
			create_order_success: '¡Pedido creado con éxito!',
			cred: 'Crédito',
			cred_deb: 'Créditos/Débitos',
			credit_card_payment: 'Pago con tarjeta de crédito',
			credit_card_payment_return: 'Devolución de pago con tarjeta de crédito',
			credit_card_receipt: 'Recibo de tarjeta de crédito',
			current: 'Actual',
			current_password: 'Contraseña actual',
			dashboard: 'Panel de control',
			date: 'Fecha',
			date_first_installment: 'Fecha del primer pago',
			debit_card_payment: 'Pago con tarjeta de débito',
			debit_card_payment_return: 'Devolución de pago con tarjeta de débito',
			debit_card_receipt: 'Recibo de tarjeta de débito',
			debt: 'Deuda',
			deleted_document: 'El documento ha sido eliminado de su registro',
			desc: 'Hacia abajo',
			description: 'Descripción',
			details: 'Detalles',
			developer: 'Desarrollador',
			directly_indicated: 'directamente indicado',
			directly_indicateds: 'Nombrado directamente',
			district: 'Barrio / distrito',
			document: 'Documento',
			document_already_registered: 'Documento ya registrado',
			documents: 'Documentos',
			donate: 'Donar',
			donor: 'Donante',
			dont_know_zip: '¿No sabe el código postal? Haga clic aquí',
			downloads: 'Descargar archivos',
			drop_images: 'Haga clic o arrastre las imágenes aquí',
			due_date: 'Madurez',
			edit: 'Para editar',
			edit_option: 'Opción de edición',
			edit_question: 'Editar pregunta',
			email: 'Correo electrónico',
			email_already_registered: 'Correo electrónico ya registrado',
			email_and_or_password_wrong: '¡Correo electrónico y/o contraseña incorrectos!',
			user_and_or_password_wrong: '¡Usuario y/o contraseña incorrectos!',
			email_for_login: 'Este correo electrónico será su inicio de sesión',
			email_or_password_wrong: 'Correo o contraseña incorrectos',
			user_or_password_wrong: 'Usuario o contraseña incorrectos',
			employee: 'Empleado',
			employees: 'Empleados',
			error_confirm_password: 'La confirmación y la nueva contraseña deben ser iguales',
			error_email: 'Formato de correo inválido',
			error_password: 'Contraseña incorrecta',
			error_phone: 'Numero de telefono invalido',
			exchange: 'Intercambio',
			expired: 'Venció',
			extra: 'Extra',
			extracts: 'Extractos',
			feedback: 'Retorno',
			final: 'Final',
			financial: 'Financiero',
			financial_payment: 'Pago Financiero',
			financial_payment_return: 'Declaración de Pago Financiero',
			financial_receipt: 'Recibo Financiero',
			financing: 'Financiación',
			financings: 'Financiación',
			first_10_pages: 'Primeras 10 páginas',
			for_year: 'Por año',
			forget_password: 'Olvidé la contraseña',
			forgot_password: 'Olvido la contraseña',
			form: 'Molde',
			form_of_payment: 'Forma de pago',
			found: 'Encontrado',
			founds: 'Encontrado',
			friend_indicated: 'Recomendado por mis amigos',
			friend_indicateds: 'Recomendado por mis amigos',
			fuel: 'Combustible',
			full_name: 'Nombre completo',
			grand_total: 'Total general',
			home: 'Comienzo',
			how_to_call: '¿Cómo quieres que te llamen?',
			images: 'Imágenes',
			in_order: 'En orden',
			in_stock: 'En stock',
			indicant: 'Indicador',
			indicated_by: 'Estás siendo nominado por',
			indicated_not_found: 'No se encontró el Indicador indicado...',
			indicateds: 'Nominados',
			info: 'Informativo',
			info_contact: 'Información del contacto',
			initial: 'Inicial',
			insert_new: 'Insertar nuevo',
			installments: 'Parcelas',
			invalid_images: 'Imágenes no válidas',
			invalid_token: 'Simbolo no valido',
			invite: 'Invitar',
			invite_friends: 'Invita a tus amigos',
			know_exact_result: '¿Sabes el valor exacto?',
			learn_more: 'Sepa mas',
			link_email: '¡Haga clic en el enlace enviado al correo electrónico registrado!',
			link_invite_copy: 'Compartir enlace copiado con éxito',
			liquid: 'Líquido',
			loading: 'Cargando',
			login: 'Entrar',
			logout: 'Vete',
			manage_users: 'Administrar usuarios',
			manager: 'Gerente',
			manufacturer: 'Fabricante',
			market: 'Mercado',
			message: 'Mensaje',
			mission: 'Misión',
			mission_vision_values: 'Misión, visión y valores',
			model: 'Modelo',
			movements: 'Movimientos',
			my_account: 'Mi cuenta',
			my_wallet: 'Mi billetera',
			mystock: 'Mi inventario',
			name: 'Nombre',
			nature: 'Naturaleza',
			new: 'Nuevo',
			new_financing: 'Nueva Financiación',
			new_password: 'Nueva contraseña',
			new_seller: 'Nuevo vendedor',
			news: 'Noticias',
			next: 'Próximo',
			nick: 'Usuario',
			no: 'No',
			no_approve: 'Sigue sin aprobar',
			no_delete: 'No borres',
			no_results_found: 'Ningún resultado encontrado',
			no_services_or_products_added: 'Ningún servicio o producto añadido',
			none_friends_indicated: 'Todavía no he recomendado a ningún amigo',
			not_authorized: 'No autorizado',
			not_found: 'No encontrado',
			not_login: '¡No se pudo iniciar sesión, verifique sus datos y vuelva a intentarlo!',
			not_orders: 'Sin giros postales',
			not_registered: '¿Aún no estás registrado? Haz clic aquí y regístrate',
			not_results: 'No se encontraron registros',
			not_self_register: 'No se puede registrar',
			not_today_entry: 'Aún no hemos tenido entradas hoy',
			notfound_ad: 'No se encontraron anuncios',
			number: 'Número',
			of: 'en',
			at: 'en',
			off: 'Acabado',
			on_total: 'De total',
			open: 'Abierto',
			opening: 'Apertura',
			operation: 'Interino',
			ordenation: 'Ordenando',
			order: 'Ordenar',
			orders: 'Peticiones',
			other: 'Otro',
			other_services_or_products: 'Otros Servicios o Productos',
			paid: 'Pagado',
			paid_date: 'Día de pago',
			partial: 'Parcialmente pagado',
			passport: 'Pasaporte',
			password: 'Contraseña',
			pay: 'Pagar',
			pay_value_in: 'Pagar el importe en',
			payable: 'Pagar',
			payer: 'Pagador',
			payment: 'Pago',
			pendent: 'Pendiente',
			personal_data: 'Datos personales',
			prefecture_data: 'Datos del Ayuntamiento',
			phone: 'Teléfono',
			pix: 'PIX',
			pix_payment: 'Pago en PIX',
			pix_payment_return: 'Devolución de pago en PIX',
			pix_receipt: 'Recibo en PIX',
			plates: 'platos',
			please_wait: 'Espere por favor',
			plus: 'Más',
			prev: 'Anterior',
			price: 'Precio',
			profile: 'Perfil',
			project: 'Proyecto',
			projects: 'Proyectos',
			purchase: 'Compra',
			quantity: 'La cantidad',
			quotation: 'Precio',
			receivable: 'Recibir',
			recipient: 'Receptor',
			recommendations: 'Recomendaciones',
			redefine_password: 'Redefinir contraseña',
			register: 'Registro',
			registers: 'Registros',
			registers_users: 'Registro de usuario',
			reject: 'Rechazar',
			remaining: 'Restante',
			required_data_not_sent: 'Datos requeridos no enviados',
			resend_email: 'Reenviar email',
			residential: 'Residencial',
			result: 'Resultado',
			results: 'Resultados',
			revoked: 'Revocado',
			rg: 'Registro General (Identidad)',
			roi: 'Ganancias diarias',
			sale: 'Venta',
			save: 'Ahorrar',
			search: 'Buscar',
			secretary: 'Secretario',
			see_advents: 'Ver los anuncios',
			see_details: 'Ver detalles',
			see_details_wallet: 'Ver detalles de la cartera',
			see_financing: 'Ver Financiamiento',
			see_order: 'Ver pedido',
			see_user: 'Ver Usuarios',
			select_vehicle: 'Seleccionar vehículo',
			self_register: 'Registro',
			seller: 'Vendedor',
			send_to: 'Enviar para',
			settings: 'Ajustes',
			share_your_link: 'Comparte tu enlace',
			show: 'Mostrar hasta',
			show_from: 'Mostrando desde',
			sign_in_to_start_your_session: 'Inicie sesión para iniciar su sesión',
			sign_up: 'Registro',
			sold: 'Vendido',
			state: 'Expresar',
			status: 'Estado',
			street: 'Calle',
			subject: 'Tema en cuestion',
			submit: 'Mandar',
			submit_placeholder: 'Por favor complete su correo electrónico',
			subscribe: 'Regístrate para recibir nuestras novedades',
			subtitle: 'Subtítulo',
			success_update_user: '¡Usuario actualizado con éxito!',
			suggestions: 'Sugerencias',
			supervisor: 'Supervisor',
			support: 'Apoyo',
			suspended: 'Suspendido',
			this_value_in: 'Ese valor en',
			title: 'Título',
			to: 'Hasta que',
			to_pay: 'Borrar',
			to_receive: 'A recibir',
			today_entry: 'Entrada de hoy',
			token_not_found_or_already_used: 'Token no encontrado o ya usado',
			total: 'Total',
			total_entry: 'Entrada total',
			transfer_payment: 'Pago por transferencia',
			transfer_payment_return: 'Devolución Pago Transferencia',
			transfer_receipt: 'Recibo de Transferencia',
			type: 'Escribe',
			update: 'Actualizar',
			used: 'Usó',
			user: 'Usuario',
			user_already_exists: '¡El usuario ya existe!',
			user_not_found: 'Usuario no encontrado',
			user_not_logged: 'Usuario no registrado',
			users: 'Usuarios',
			users_list: 'Lista de usuarios',
			list: 'Lista',
			valid_images: 'Imágenes válidas',
			value: 'Valor',
			value_donate_on: 'Cantidad de la donación en',
			value_financed: 'monto financiado',
			value_installment: 'Valor de la cuota',
			values: 'Valores',
			vehicle: 'Vehículo',
			vehicles: 'Vehículos',
			view_order: 'Ver pedido',
			vision: 'Vista',
			visitor: 'Visitante',
			voucher: 'Vale',
			wait_confirm: 'Esperando confirmacion',
			waiting: 'Esperando',
			waiting_email:
				'El registro se completó con éxito, ahora todo lo que necesita para confirmar es el correo electrónico.,',
			waiting_payment: 'En espera de pago',
			wallet_generate: 'Generar Monedero',
			want_donate: 'Quiero donar',
			we_are: 'Están',
			welcome: 'Sea bienvenido',
			who_we_are: 'Quienes somos',
			who_we_are_subtitle: 'Una breve descripción de la',
			will_expire_in: 'expirará en',
			with: 'con',
			year: 'Año',
			years: 'Años',
			yes: 'Sí',
			your_dashboard: 'Este es su panel de control.',
			zipcode: 'CÓDIGO POSTAL',
			January: 'Enero',
			February: 'Febrero',
			March: 'Marzo',
			April: 'Abril',
			May: 'Puede',
			June: 'Junio',
			July: 'Julio',
			August: 'Agosto',
			September: 'Septiembre',
			October: 'Octubre',
			November: 'Noviembre',
			December: 'Diciembre',
			location: 'Localización',
			portfolio: 'Portafolio',
			how_it_works: 'Como funciona',
			public_works: 'Obras Públicas',
			private_works: 'Obras Privadas',
			a_crase: 'Él',
			ao: 'a',
			lead: 'Juntos estaremos construyendo un nuevo futuro.',
			system: 'Sistema',
			not_permission: 'No tienes permiso para acceder a esta página',
			in_construction: 'En construcción',
			reports: 'Informes',
			transparency: 'Transparencia',
			casa_verde_amarela: 'Casa Verde Amarilla',
			people: 'Gente',
			person: 'Persona',
			register_people: 'Registro de Personas',
			fisica: 'Persona física',
			juridica: 'Persona jurídica',
			clean: 'Limpio',
			personal_infos: 'Información',
			prefecture_infos: 'Información',
			nationality: 'Nacionalidad (País de nacimiento)',
			naturalness: 'Naturalidad (Ciudad donde naciste)',
			rgAgency: 'emisor de RG',
			rgUf: 'Estado del RG',
			rgDate: 'Fecha de emisión de RG',
			cnhDate: 'Fecha de Emisión CNH',
			gender: 'Género',
			schooling: 'Educación',
			profession: 'Profesión',
			maritalStatus: 'Estado civil',
			motherName: 'Nombre de la madre',
			fatherName: 'Nombre del Padre',
			dependents: 'Dependientes',
			male: 'Masculino',
			feminine: 'Femenino',
			single: 'No casado',
			married: 'Casado',
			widower: 'Viudo',
			divorced: 'Divorciado',
			personal_addresses: 'Direcciones',
			prefecture_addresses: 'Direcciones',
			remove: 'Para eliminar',
			personal_contacts: 'Contactos',
			prefecture_contacts: 'Contactos',
			field_required: 'Campo obligatorio',
			data_saved: 'Datos guardados',
			data_added: 'Datos agregados',
			resume_data: 'Resumen de datos',
			no_literate: 'No alfabetizado',
			fundamental_incomplete: 'Fundamental - Incompleto',
			fundamental_complete: 'Fundamental - Completo',
			middle_incomplete: 'Medio - Incompleto',
			middle_complete: 'Medio - Completo',
			superior_incomplete: 'Superior incompleta',
			superior_complete: 'Superior completo',
			pos_incomplete: 'Lato sensu - Incompleto',
			pos_complete: 'Lato sensu - Completo',
			master_incomplete: 'Maestría - Incompleta',
			master_complete: 'Maestría - Completa',
			doctorate_incomplete: 'Doctorado - Incompleto',
			doctorate_complete: 'Doctorado - Completo',
			invalid_contact: 'Formato de contacto no válido',
			obs: 'Comentarios',
			allRightsReserved: 'Todos los derechos reservados.',
			want_now_more_about: 'Quiero saber más sobre',
			no_repeat: 'Los datos no se pueden repetir',
			personal_pcva: 'Programa Casa Verde y Amarilla',
			compRenda: 'Tipo de comprobante de ingresos',
			funcPublico: 'Es un funcionario público',
			decImpRenda: 'Declarar Impuesto a la Renta',
			tempoCarteira: '¿Cuánto tiempo tiene de registro en su cartera',
			rendaIndividual: 'Ingresos Brutos Individuales',
			comporRenda: 'Ve a componer encaje con alguien',
			valorRenda2: '¿Cuál es el valor de la segunda renta',
			nascMaisVelhoRenda2: '¿Cuál es la fecha de nacimiento del mayor?',
			fgts: 'Cantidad de su FGTS',
			entrada: '¿Tiene cuota de entrada? En caso afirmativo, qué cantidad',
			restricao: '¿Tienes alguna restricción en tu nombre? (SPC/Serasa)',
			interesse: '¿Cuál es la región de interés?',
			estadoInteresse: 'Expresar',
			cidadeInteresse: 'Ciudad',
			bairroInteresse: 'Barrio',
			program: 'Programa',
			programs: 'Software',
			privacy_policies: 'Política de privacidad',
			prefectures: 'Prefecturas',
			prefecture: 'Municipalidad',
			fantasy: 'Fantasía',
			legalNature: 'Naturaleza jurídica',
			dev_econom: 'Desarrollo economico',
			nis: 'PIS/NIS',
			nis_already_registered: 'PIS/NIS ya registrado',
			group: 'Grupo',
			created: 'Registrado en',
			person_found_in_other_prefecture: 'Persona ya empadronada en otro municipio',
			print: 'Imprimir',
			datas: 'Datos',
			qualquer: 'Alguna',
			dou_fe: 'Confirmo que la información anterior es correcta.',

			change_indicant: 'Cambio',
			password_security: 'Contraseña de seguridad',
			confirm_password_security: 'Confirmar la contraseña de seguridad',
			plan: 'plano',
			terms: 'Acepto los términos de uso',
			user_inactive: 'Usuario inactivo',
		},
	},
};

export { messages };
