const messages = {
	en: {
		translations: {
			all_departments: 'All departments',
			about: 'About Us',
			above_the_same: 'Above exactly the same',
			action: 'Action',
			ad: 'Announcement',
			add: 'Add',
			add_option: 'Add option',
			add_payment: 'Add payment',
			add_question: 'Add question',
			add_receipt: 'Add Receipt',
			address: 'Address',
			admin: 'Administration',
			advance: 'Advance',
			affected: 'Hit',
			air_transport: 'Air Transport',
			all: 'All',
			allies_of: 'Allies of',
			almost_there: 'Almost there',
			already_offset: 'I already compensated',
			already_registered: 'Is it already registered?Click here and between',
			and: 'e',
			and_more: 'and more',
			approve: 'Approve',
			asc: 'Ascending',
			awaiting_email_confirmation: 'Awaiting confirmation of the email!',
			back: 'Come back',
			balance: 'Balance',
			balance_avaliable: 'Balance available',
			banned: 'Banned',
			before_contact: 'Before you contact us',
			binary: 'Binary',
			birth: 'Birth',
			blind_man: 'Reversal',
			but_possible_register:
				'Mesmo assim é possível fazer o seu cadastro, porém não ficará relacionado a nenhum amigo indicante.',
			buyer: 'Buyer',
			by: 'for',
			bye: 'See you later',
			call_to: 'Call to',
			canceled: 'Canceled',
			calls: 'So -called',
			cancel: 'Cancel',
			cash_payment: 'Cash payment',
			cash_payment_return: 'Return of cash payment',
			cash_receipt: 'Cash receipt',
			check_payment: 'Payment in check',
			check_payment_return: 'Payment Return on Check',
			check_receipt: 'Check Receipt',
			choose: 'Choose',
			city: 'City',
			claims: 'Claims',
			click_and_know: 'CLICK AND KNOW',
			click_to_copy_the_wallet: 'Click to copy the wallet',
			client: 'Client',
			clients: 'Customers',
			cnh: 'CNH (Qualification)',
			cnpj: 'cnpj',
			cnpj_invalid: 'CNPJ Invalid',
			color: 'cor',
			commercial: 'Commercial',
			company: 'Company Name',
			complement: 'Complement',
			confirm_approve: 'Confirm approval',
			confirm_delete: 'Confirm exclusion',
			confirm_new_password: 'Confirm a new password',
			confirm_password: 'Confirm the access password',
			confirmed: 'Confirmed',
			confirmed_email: 'Confirmed email',
			contact: 'Contact',
			contract: 'Contract',
			copy: 'Copy',
			copy_success: 'Copied successfully!',
			correspondences: 'Correspondences',
			country: 'Country',
			cpf: 'cpf',
			cpf_invalid: 'Invalid CPF',
			create_order_success: 'Request created successfully!',
			cred: 'Credit',
			cred_deb: 'Credits/Debts',
			credit_card_payment: 'Credit card payment',
			credit_card_payment_return: 'Credit Card Payment Return',
			credit_card_receipt: 'Credit Card Receipt',
			current: 'Current',
			current_password: 'Current password',
			dashboard: 'Control panel',
			date: 'data',
			date_first_installment: 'First payment date',
			debit_card_payment: 'Debit card payment',
			debit_card_payment_return: 'Return of payment on debit card',
			debit_card_receipt: 'Debt Card Receipt',
			debt: 'Debt',
			deleted_document: 'Document was excluded from your registration',
			desc: 'Downward',
			description: 'Description',
			details: 'Details',
			developer: 'Developer',
			directly_indicated: 'Directly indicated',
			directly_indicateds: 'Indicated directly',
			district: 'Neighborhood / district',
			document: 'Document',
			document_already_registered: 'Document already registered',
			documents: 'Document',
			donate: 'Just',
			donor: 'Donor',
			dont_know_zip: "Don't you know the zip code?click here",
			downloads: 'Download files',
			drop_images: 'Click or drag the images here',
			due_date: 'Maturity',
			edit: 'To edit',
			edit_option: 'Edit option',
			edit_question: 'Issue a question',
			email: 'email',
			email_already_registered: 'E-mail already registered',
			email_and_or_password_wrong: 'Email and/or password wrong!',
			user_and_or_password_wrong: 'Wrong user and/or password!',
			email_for_login: 'This email will be your login',
			email_or_password_wrong: 'Incorrect email or password',
			user_or_password_wrong: 'incorrect username or password',
			employee: 'Employee',
			employees: 'Employees',
			error_confirm_password: 'Confirmation and new password must be equal',
			error_email: 'Invalid email format',
			error_password: 'Incorrect password',
			error_phone: 'INVALID PHONE NUMBER',
			exchange: 'Exchange',
			expired: 'Expired',
			extra: 'extra',
			extracts: 'Extracts',
			feedback: 'Return',
			final: 'final',
			financial: 'Financial',
			financial_payment: 'Financial payment',
			financial_payment_return: 'Return of Financial Payment',
			financial_receipt: 'Financial Receipt',
			financing: 'Financing',
			financings: 'Financing',
			first_10_pages: 'First 10 pages',
			for_year: 'Per annum',
			forget_password: 'I forgot the password',
			forgot_password: 'Forgot password',
			form: 'Form',
			form_of_payment: 'Form of payment',
			found: 'Found',
			founds: 'Found',
			friend_indicated: 'Indicated by my friends',
			friend_indicateds: 'Nominated by my friends',
			fuel: 'Fuel',
			full_name: 'Full name',
			grand_total: 'Grand total',
			home: 'Start',
			how_to_call: 'How you want to be called',
			images: 'Images',
			in_order: 'In order',
			in_stock: 'In stock',
			indicant: 'Indicating',
			indicated_by: 'You are being indicated by',
			indicated_not_found: 'The informed indicent was not found ...',
			indicateds: 'Nominated',
			info: 'Informative',
			info_contact: 'Contact information',
			initial: 'Initial',
			insert_new: 'Enter Novo',
			installments: 'Installments',
			invalid_images: 'Invalid images',
			invalid_token: 'Invalid token',
			invite: 'To invite',
			invite_friends: 'Invite your friends',
			know_exact_result: 'Do you know the exact value?',
			learn_more: 'Know more',
			link_email: 'Click on the link sent to the registered email!',
			link_invite_copy: 'Successful copied sharing link',
			liquid: 'Liquid',
			loading: 'Loading',
			login: 'To enter',
			logout: 'Leave',
			manage_users: 'Manage users',
			manager: 'Manager',
			manufacturer: 'Manufacturer',
			market: 'mercado',
			message: 'Message',
			mission: 'Mission',
			mission_vision_values: 'Mission, vision and values',
			model: 'Model',
			movements: 'Movements',
			my_account: 'My account',
			my_wallet: 'My wallet',
			mystock: 'My stock',
			name: 'Name',
			nature: 'Nature',
			new: 'novo',
			new_financing: 'New financing',
			new_password: 'New password',
			new_seller: 'New seller',
			news: 'News',
			next: 'Next',
			nick: 'User',
			no: 'Not',
			no_approve: 'Not yet approve',
			no_delete: 'Do not exclude',
			no_results_found: 'no results found',
			no_services_or_products_added: 'No service or product added',
			none_friends_indicated: "I haven't indicated any friends yet",
			not_authorized: 'Not authorized',
			not_found: 'Not found',
			not_login: 'It was not possible to enter, check the data and try again!',
			not_orders: 'No payment orders',
			not_registered: "Isn't it yet registered?CLICK HERE AND REGISTER",
			not_results: 'No records found',
			not_self_register: 'It was not possible to register',
			not_today_entry: "We haven't had entrances yet today",
			notfound_ad: 'No ads found',
			number: 'Number',
			of: 'of',
			at: 'em',
			off: 'Off',
			on_total: 'Of the total of',
			open: 'Open',
			opening: 'Opening',
			operation: 'Performance',
			ordenation: 'Ordering',
			order: 'Order',
			orders: 'Requests',
			other: 'Other',
			other_services_or_products: 'Other services or products',
			paid: 'I pay',
			paid_date: 'Pay day',
			partial: 'Partially payment',
			passport: 'Passport',
			password: 'Password',
			pay: 'Pay',
			pay_value_in: 'Pay the value in',
			payable: 'Pay',
			payer: 'Payer',
			payment: 'Payment',
			pendent: 'Pending',
			personal_data: 'Personal data',
			prefecture_data: 'City Hall data',
			phone: 'fone',
			pix: 'pix',
			pix_payment: 'PAIN PAYMENT',
			pix_payment_return: 'PAIN PAYMENT RETURN',
			pix_receipt: 'Receipt in Pix',
			plates: 'Signs',
			please_wait: 'Please wait',
			plus: 'plus',
			prev: 'anterior',
			price: 'Price',
			profile: 'Profile',
			project: 'Project',
			projects: 'Projects',
			purchase: 'Purchase',
			quantity: 'The amount',
			quotation: 'Price',
			receivable: 'Receive',
			recipient: 'Recipient',
			recommendations: 'Recommendations',
			redefine_password: 'Redefine password',
			register: 'Registry',
			registers: 'Records',
			registers_users: 'User Registrations',
			reject: 'Reject',
			remaining: 'Remaining',
			required_data_not_sent: 'Necessary data not sent',
			resend_email: 'REVIEW THE EMAIL',
			residential: 'Residential',
			result: 'Result',
			results: 'Results',
			revoked: 'Revoked',
			rg: 'General Registration (Identity)',
			roi: 'Daily gains',
			sale: 'Sale',
			save: 'To save',
			search: 'Seek',
			secretary: 'Secretary',
			see_advents: 'See the ads',
			see_details: 'see details',
			see_details_wallet: 'See wallet details',
			see_financing: 'View Financing',
			see_order: 'See order',
			see_user: 'See users',
			select_vehicle: 'Select the vehicle',
			self_register: 'Register',
			seller: 'Salesman',
			send_to: 'Send to',
			settings: 'settings',
			share_your_link: 'Share your link',
			show: 'Range up',
			show_from: 'Displaying from',
			sign_in_to_start_your_session: 'Make the login to start your session',
			sign_up: 'Register',
			sold: 'Sold',
			state: 'state',
			status: 'status',
			street: 'Two',
			subject: 'Subject matter',
			submit: 'To send',
			submit_placeholder: 'Please fill in your email',
			subscribe: 'Subscribe to receive our news',
			subtitle: 'Caption',
			success_update_user: 'User updated successfully!',
			suggestions: 'Suggestions',
			supervisor: 'supervisor',
			support: 'Support',
			suspended: 'Suspended',
			this_value_in: 'This value in',
			title: 'Title',
			to: 'Until',
			to_pay: 'Turn off',
			to_receive: 'Receivable',
			today_entry: "Today's entry",
			token_not_found_or_already_used: 'Token not found or has been used',
			total: 'total',
			total_entry: 'Total entry',
			transfer_payment: 'Transfer Payment',
			transfer_payment_return: 'Transfer Payment Return',
			transfer_receipt: 'Transfer Receipt',
			type: 'Type',
			update: 'To update',
			used: 'Used',
			user: 'User',
			user_already_exists: 'User already exists!',
			user_not_found: 'User not found',
			user_not_logged: 'User not logged in',
			users: 'Users',
			users_list: 'LIST OF USERS',
			list: 'List',
			valid_images: 'Valid images',
			value: 'valor',
			value_donate_on: 'Donation value in',
			value_financed: 'Funded value',
			value_installment: 'Installment value',
			values: 'Values',
			vehicle: 'Vehicle',
			vehicles: 'Vehicles',
			view_order: 'See order',
			vision: 'Eyesight',
			visitor: 'Visitor',
			voucher: 'voucher',
			wait_confirm: 'Waiting confirmation',
			waiting: 'Waiting',
			waiting_email: 'The registration was successfully made, now only confirm the email.',
			waiting_payment: 'Awaiting payment',
			wallet_generate: 'Wallet',
			want_donate: 'I want to donate',
			we_are: 'Are',
			welcome: 'Welcome',
			who_we_are: 'Who we are',
			who_we_are_subtitle: 'A brief description of the',
			will_expire_in: 'will expire in',
			with: 'com',
			year: 'Yet',
			years: 'Years',
			yes: 'sim',
			your_dashboard: 'This is your control panel.',
			zipcode: 'cep',
			January: 'January',
			February: 'February',
			March: 'March',
			April: 'April',
			May: 'May',
			June: 'June',
			July: 'July',
			August: 'August',
			September: 'September',
			October: 'October',
			November: 'November',
			December: 'December',
			location: 'Location',
			portfolio: 'Portfolio',
			how_it_works: 'How it works',
			public_works: 'Public works',
			private_works: 'Private works',
			a_crase: 'at',
			ao: 'to',
			lead: 'Together we will be building a new future.',
			system: 'System',
			not_permission: 'You are not allowed to access this page',
			in_construction: 'Under construction',
			reports: 'Reports',
			transparency: 'Transparência',
			casa_verde_amarela: 'Yellow green house',
			people: 'People',
			person: 'Person',
			register_people: 'Registration of people',
			fisica: 'Physical person',
			juridica: 'Legal person',
			clean: 'Clean',
			personal_infos: 'Information',
			prefecture_infos: 'Information',
			nationality: 'Nationality (country where it was born)',
			naturalness: 'Naturalness (city where you were born)',
			rgAgency: 'RG emitter',
			rgUf: 'RG',
			rgDate: 'RG issuance date',
			cnhDate: 'CNH ISSUE DATE',
			gender: 'Genre',
			schooling: 'Schooling',
			profession: 'Profession',
			maritalStatus: 'Civil status',
			motherName: "Mother's name",
			fatherName: "Father's name",
			dependents: 'Dependents',
			male: 'Masculine',
			feminine: 'Female',
			single: 'Not married',
			married: 'Married',
			widower: 'Widower',
			divorced: 'Divorced',
			personal_addresses: 'Adresses',
			prefecture_addresses: 'Adresses',
			remove: 'remover',
			personal_contacts: 'Contacts',
			prefecture_contacts: 'Contacts',
			field_required: 'Compulsory completion',
			data_saved: 'Saved data',
			data_added: 'Added data',
			resume_data: 'Data Summary',

			no_literate: 'Non -literate',
			fundamental_incomplete: 'Fundamental - Incomplete',
			fundamental_complete: 'Fundamental - Complete',
			middle_incomplete: 'Middle - Incomplete',
			middle_complete: 'Middle - Complete',
			superior_incomplete: 'Superior - Incomplete',
			superior_complete: 'SUPERIOR - COMPLETE',
			pos_incomplete: 'Side Side - incomplete',
			pos_complete: 'Side Side - Complete',
			master_incomplete: 'Master - Incomplete',
			master_complete: 'Master - Complete',
			doctorate_incomplete: 'Doctorate - Incomplete',
			doctorate_complete: 'Doctorate - Complete',

			invalid_contact: 'Invalid contact format',
			obs: 'Comments',
			allRightsReserved: 'All rights reserved.',
			want_now_more_about: 'I want to know more about',
			no_repeat: 'Data cannot be repeated',

			personal_pcva: 'Casa Verde and Yellow Program',
			compRenda: 'Type of Proof of Income',
			funcPublico: 'It is a civil servant',
			decImpRenda: 'Declares Income Tax',
			tempoCarteira: 'How long registration has in a portfolio',
			rendaIndividual: 'Individual gross income',
			comporRenda: 'Will compose income with someone',
			valorRenda2: 'What is the value of the second income',
			nascMaisVelhoRenda2: 'What is the date of birth of the eldest',
			fgts: 'Value of your FGTS',
			entrada: 'Do you have input value?If so, what value',
			restricao: 'Do you have restriction on your behalf?(SPC/SERASA)',
			interesse: 'What is the region of interest',
			estadoInteresse: 'state',
			cidadeInteresse: 'City',
			bairroInteresse: 'Neighborhood',
			program: 'Program',
			programs: 'Software',
			privacy_policies: 'Privacy Policies',
			prefectures: 'Municipalities',
			prefecture: 'City hall',
			fantasy: 'fantasia',
			legalNature: 'Legal nature',
			dev_econom: 'Economic development',
			nis: 'Pis/nis',
			nis_already_registered: 'PIS/NIS already registered',
			group: 'Group',
			created: 'Registered in',
			person_found_in_other_prefecture: 'Person already registered in another municipality',
			print: 'Print',
			datas: 'Data',
			qualquer: 'Any',
			dou_fe: 'I confirm that the above information is correct.',

			change_indicant: 'Change',
			password_security: 'Safety password',
			confirm_password_security: 'Confirm the safety password',
			plan: 'plano',
			terms: 'I accept the Terms of Use',
			user_inactive: 'Inactive user',
		},
	},
};

export { messages };
